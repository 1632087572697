/* eslint-disable react-hooks/exhaustive-deps */
import { styled } from '@mui/material/styles';
import { Accordion, AccordionDetails, Autocomplete, Divider, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import React, { useEffect, useMemo, useState } from 'react'
import UserAutocomplete from '../../components/users/UserAutocomplete';
import { Box } from '@mui/system';
import { useSelector } from 'react-redux';
import { IRootStore } from '../../store/store';
import useConfig from '../../hooks/useConfig';
import { IClient } from '../../api/models/response/IClientResponse';
import useClients from '../../hooks/useClients';

type Props = {
    formik: any;
    isEdit: boolean;
}


const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .1)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const OrderClient = ({ formik, isEdit }: Props) => {

    const [currentUser, setcurrentUser] = useState<null | IClient>(null);
    const regions = useSelector((state: IRootStore) => state.config.regions)
    const [cities, setCities] = useState<Array<any>>([]);
    const [citiesAreLoading, setCitiesAreLoading] = useState<boolean>(false);
    const [citiesAreTouched, setCitiesAreTouched] = useState<boolean>(false);
    const { getClientById } = useClients();

    const { getCitiesFromRegion } = useConfig()

    const handleSetUser = (user: IClient | null) => {
        if (!user) {
            formik.setFieldValue('client', "")
            formik.setFieldValue('clientMail', "")
            formik.setFieldValue('addres', "")
            formik.setFieldValue('phone', "")
            setcurrentUser(null)

        } else {
            setcurrentUser(user)
            formik.setFieldValue('client', user._id)
            formik.setFieldValue('clientMail', "")
            formik.setFieldValue('addres', user.di1)
            formik.setFieldValue('phone', user.te1)
        }

    }

    const handleGetCities = (region: string, city: string) => {
        setCitiesAreLoading(true)
        setCitiesAreTouched(true)
        getCitiesFromRegion(region, city)
            .then(res => {
                setCities(res.map((c: any) => ({ id: c._id, label: c.name })))
                setCitiesAreLoading(false)
            })
            .catch((err) => setCitiesAreLoading(false))
    }
    useEffect(() => {
        if (isEdit && formik.values.client) {
            getClientById(formik.values.client)
                .then(res => {
                    setcurrentUser(res)
                })
        }
    }, [formik.values.client]);
    useEffect(() => {
        if (formik.values.department && formik.values.department !== '') {
            handleGetCities(formik.values.department, '')
        } else {
            setCities([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.department]);

    const getRegionNameById = useMemo(() => {
        return (id: string) => {
            const foundItem = regions.find(item => item._id === id);
            return foundItem ? foundItem.name : null;
        };
    }, [regions]);

    const getCityNameById = useMemo(() => {
        return (id: string) => {
            const foundItem = cities.find(item => item.id === id);
            return foundItem ? foundItem.label : null;
        };
    }, [cities])

    return (
        <Accordion defaultExpanded>
            <AccordionSummary>
                <Typography variant='h6'>Cliente</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            label={isEdit ? 'Ultima modificación' : 'Fecha'}
                            name='date'
                            type='date'
                            disabled={isEdit}
                            size='small'
                            value={formik.values.date}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onBlur={formik.handleBlur}
                            error={formik.touched.date && Boolean(formik.errors.date)}
                            helperText={formik.touched.date && formik.errors.date}
                            onChange={formik.handleChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>{formik.touched.date && Boolean(formik.errors.date)}
                    <Grid item xs={12} >
                        {/* <TextField variant='outlined' label='Id o Nombre Cliente' fullWidth/> */}
                        <UserAutocomplete
                            onSelectUser={handleSetUser}
                            value={formik.values.client}
                            error={formik.touched.client && Boolean(formik.errors.client)} />
                    </Grid>
                    <Grid item xs={12} md={4} display="flex" alignItems='center'>
                        <TextField
                            value={currentUser?.rso ?? ''}
                            variant='outlined'
                            label='Razón social'
                            size='small'
                            InputProps={{
                                readOnly: true,
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth />
                    </Grid>
                    <Grid item xs={12} md={4} display="flex" alignItems='center'>
                        <TextField
                            value={currentUser?.cliente ?? ''}
                            variant='outlined'
                            label='Cédula'
                            size='small'
                            InputProps={{
                                readOnly: true,
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth />
                    </Grid>
                    <Grid item xs={12} md={4} display="flex" alignItems='center'>
                        <TextField
                            value={currentUser?.te1 ?? ''}
                            variant='outlined'
                            label='Teléfono'
                            size='small'
                            name='phone'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.phone && Boolean(formik.errors.phone)}
                            helperText={formik.touched.phone && formik.errors.phone}
                            fullWidth />
                    </Grid>
                    <Grid item xs={12} md={4} display="flex" alignItems='flex-start'>
                        <TextField
                            value={formik.values.clientMail}
                            variant='outlined'
                            label='Correo'
                            size='small'
                            name='clientMail'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.clientMail && Boolean(formik.errors.clientMail)}
                            helperText={formik.touched.clientMail && formik.errors.clientMail}
                            fullWidth />
                    </Grid>
                    <Grid item xs={12} md={4} display="flex" alignItems='center'>
                        <FormControl variant='outlined' fullWidth>
                            <Autocomplete
                                fullWidth
                                size='small'
                                value={getRegionNameById(formik.values.department)}
                                options={regions.map(reg => ({ id: reg._id, label: reg.name }))}
                                onChange={(e, value) => {
                                    formik.setFieldValue('department', value?.id)
                                }}
                                renderInput={(params) =>
                                    <TextField {...params}
                                        label='Departamento'
                                        name='department'
                                        error={formik.touched.department && Boolean(formik.errors.department)}
                                        helperText={(formik.touched.department && formik.errors.department) || " "}
                                    />
                                }
                                renderOption={(props, option) => (
                                    <Box component="li" {...props} key={option.id}>
                                        {option.label}
                                    </Box>
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4} display="flex" alignItems='center'>
                        <FormControl variant='outlined' fullWidth>
                            <Autocomplete
                                loading={citiesAreLoading}
                                size='small'
                                value={getCityNameById(formik.values.city)}
                                options={cities}
                                onChange={(e, value) => {
                                    formik.setFieldValue('city', value?.id)
                                }}
                                disabled={cities.length === 0 && !citiesAreTouched}
                                renderInput={(params) =>
                                    <TextField {...params}
                                        label='Ciudad'
                                        name='city'
                                        error={formik.touched.city && Boolean(formik.errors.city)}
                                        helperText={(formik.touched.city && formik.errors.city) || " "}
                                        disabled={cities.length === 0 && !citiesAreTouched}
                                        onChange={(e) => {
                                            handleGetCities(formik.values.department, e.target.value)
                                        }}
                                        onBlur={(e) => {
                                            formik.handleBlur(e)
                                            if (formik.values.city === '') {
                                                handleGetCities(formik.values.department, '')
                                            }
                                        }}
                                    />}
                                renderOption={(props, option) => (
                                    <Box component="li" {...props} key={option.id}>
                                        {option.label}
                                    </Box>
                                )
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12} display="flex" alignItems='center'>
                        <TextField
                            variant='outlined'
                            label='Dirección'
                            size='small'
                            name='addres'
                            value={formik.values.addres}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.addres && Boolean(formik.errors.addres)}
                            helperText={formik.touched.addres && formik.errors.addres}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormControl variant='outlined' fullWidth>
                            <InputLabel
                                id="select-label-department"
                                shrink={true}
                            >Tipo de documento</InputLabel>
                            <Select
                                labelId='select-label-department'
                                label='Tipo de documento'
                                name='documentType'
                                value={formik.values.documentType}
                                onChange={formik.handleChange}
                                fullWidth
                                notched={true}
                                onBlur={formik.handleBlur}
                                error={formik.touched.documentType && Boolean(formik.errors.documentType)}
                                size='small'
                            >
                                <MenuItem value='PEDIDO'>Pedido</MenuItem>
                                <MenuItem value='DEVOLUCION'>Devolución</MenuItem>
                                <MenuItem value='GARANTIAS'>Garantías</MenuItem>
                                <MenuItem value='TRASLADO'>Traslado</MenuItem>
                                <MenuItem value='ENTRADAS'>Entradas</MenuItem>
                                <MenuItem value='SALIDAS'>Salidas</MenuItem>
                            </Select>
                            <FormHelperText>{formik.touched.documentType && formik.errors.documentType}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormControl variant='outlined' fullWidth>
                            <InputLabel
                                id="select-label-department"
                                shrink={true}
                            >
                                Tipo de precio
                            </InputLabel>
                            <Select
                                labelId='select-label-department'
                                label='Tipo de precio'
                                name='priceType'
                                value={formik.values.priceType}
                                notched={true}
                                fullWidth
                                size='small'
                                onBlur={formik.handleBlur}
                                error={formik.touched.priceType && Boolean(formik.errors.priceType)}
                                onChange={formik.handleChange}
                            >
                                <MenuItem value='PUBLICO'>Público</MenuItem>
                                <MenuItem value='MAYORISTA'>Mayorista</MenuItem>
                                <MenuItem value='ESPECIAL'>Especial</MenuItem>
                            </Select>
                            <FormHelperText>{formik.touched.priceType && formik.errors.priceType}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormControl variant='outlined' fullWidth>
                            <InputLabel
                                id="select-label-department"
                                shrink={true}
                            >Tipo de pago</InputLabel>
                            <Select
                                labelId='select-label-department'
                                label='Tipo de pago'
                                name='paymentMethod'
                                notched={true}
                                fullWidth
                                size='small'
                                value={formik.values.paymentMethod}
                                onChange={formik.handleChange}
                            >
                                <MenuItem value={'CREDITO'}>Crédito</MenuItem>
                                <MenuItem value={'CONTADO'}>Contado</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormControl variant='outlined' fullWidth>
                            <InputLabel
                                id="select-label-department"
                                shrink={true}
                            >Tipo de factura</InputLabel>
                            <Select
                                labelId='select-label-department'
                                label='Tipo de documento'
                                name='invoiceType'
                                onChange={formik.handleChange}
                                value={formik.values.invoiceType}
                                fullWidth
                                notched={true}
                                size='small'
                            >
                                <MenuItem value='PEDIDO'>Pedido</MenuItem>
                                <MenuItem value='DEVOLUCION'>Devolución</MenuItem>
                                <MenuItem value='GARANTIAS'>Garantías</MenuItem>
                                <MenuItem value='TRASLADO'>Traslado</MenuItem>
                                <MenuItem value='ENTRADAS'>Entradas</MenuItem>
                                <MenuItem value='SALIDAS'>Salidas</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            label='Descuento'
                            name='financialDiscount'
                            type='number'
                            size='small'
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={formik.values.financialDiscount}
                            onChange={formik.handleChange}
                        />
                    </Grid>
                    {
                        formik.values.paymentMethod === 'CREDITO' &&
                        <Grid item xs={12} md={4}>
                            <TextField
                                label='Termino'
                                name='term'
                                type='number'
                                size='small'
                                fullWidth
                                value={formik.values.term}
                                onBlur={formik.handleBlur}
                                error={formik.touched.term && Boolean(formik.errors.term)}
                                helperText={formik.touched.term && formik.errors.term}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                    }

                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}

export default OrderClient