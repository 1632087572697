import { styled } from '@mui/material/styles';
import { Accordion, AccordionDetails, Typography } from '@mui/material'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import React from 'react'
import OrderShoesAdder from './OrderShoesAdder';
import OrderShoesTable from './OrderShoesTable';

type Props = {
    formik: any;
}


const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .1)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const OrderItems = ({ formik }: Props) => {

    const addShoeToOrder = (shoe: any) => {
        formik.setFieldValue('shoes', [...formik.values.shoes, ...shoe])
    }

    const onDeleteRow = (idToDelete: string) => {
        const updatedShoes = formik.values.shoes.filter((shoe: any) => shoe._id !== idToDelete);
        formik.setFieldValue('shoes', updatedShoes);
    };


    return (
        <Accordion>
            <AccordionSummary>
                <Typography variant='h6'>Items</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <OrderShoesAdder addShoe={(value: any) => addShoeToOrder(value)} addedShoes={formik.values.shoes} />
                <OrderShoesTable shoes={formik.values.shoes} onDeleteRow={onDeleteRow} priceType={formik.values.priceType} />
            </AccordionDetails>
        </Accordion>
    )
}

export default OrderItems