import React from 'react'
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import FileUpload from './FileUpload';
import { apiUrls } from '../../api/apiUrls';


type Props = {
    isOpen: boolean,
    onClose: () => void
}

const ClientsUploadModal = (props: Props) => {
    const { isOpen, onClose } = props;

    const handleClose = () => {
        onClose();
    }
    return (
        <Dialog
            open={isOpen}
            onClose={() => handleClose()}
        >
            <DialogTitle>Subir archivo</DialogTitle>
            <DialogContent>
                <FileUpload apiUrl={apiUrls.security.PutUsers} handleCancel={() => handleClose()} />
            </DialogContent>
        </Dialog>
    )
}

export default ClientsUploadModal;