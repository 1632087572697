import axios, { AxiosRequestConfig } from 'axios';
import { BASE_URL, NODE_API_BASE_URL } from './apiUrls'

const api = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },

});


export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' }
});

export const axiosNode = axios.create({
    baseURL: NODE_API_BASE_URL,
})


axiosNode.interceptors.request.use(
    (config: AxiosRequestConfig) => {
        const token = localStorage.getItem('access');
        if (token && config.headers) {
            config.headers.Authorization = `${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosNode.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            localStorage.removeItem('access');
        }
        return Promise.reject(error);
    }
);

axiosPrivate.interceptors.request.use((request) => {
    return request
}, (err) => err);

axiosPrivate.interceptors.response.use((response) => {
    return response
}, (err) => err);

export default api