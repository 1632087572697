import { Button, Card, CardContent, Grid, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ViewHeader from '../../components/layout/ViewHeader'
import { useNavigate, useParams } from 'react-router-dom'
import useClients from '../../hooks/useClients'
import AppLoader from '../../components/layout/AppLoader'
import { IClient } from '../../api/models/response/IClientResponse'
import { Box } from '@mui/system'

type Props = {
    label: string;
    value: string;
}

const ClientField = ({ label, value }: Props) => {
    return (
        <Grid item xs={12} md={6}>
            <TextField
                variant='outlined'
                disabled
                label={label}
                value={value}
                fullWidth
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </Grid>
    )

}

const ClientDetail = () => {
    const [client, setClient] = useState<IClient | undefined>(undefined);
    const { id } = useParams();
    const navigate = useNavigate();
    const { getClientById } = useClients();

    const handleGetClient = async () => {
        if (id) {
            getClientById(id)
                .then(res => setClient(res))
        }
    }

    useEffect(() => {
        handleGetClient()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <ViewHeader text="Detalle Cliente" />
            <Card>
                <CardContent>
                    {
                        client ?


                            <Grid container spacing={2} mt={4}>
                                <ClientField label="Documento" value={`${client.nce}-${client.dig_ver}`} />
                                <ClientField label="Razón Social" value={client.rso} />
                                <ClientField label="Nombre" value={`${client?.nom1} ${client?.nom2}`} />
                                <ClientField label={client.di2 ? "Dirección 1" : "Dirección"} value={client.di1} />
                                {client.di2 && <ClientField label="Dirección 2" value={client.di2} />}
                                <ClientField label="Teléfono" value={client.te1} />
                            </Grid>
                            :
                            <AppLoader text="Cargando..." hasLogo={false} />
                    }
                    <Box display="flex" justifyContent={{ md: 'flex-end', sm: 'center' }} width="100%" mt={4}>
                        <Box width={{ sm: '50%', md: '20%' }} >
                            <Button
                                variant='outlined'
                                color='primary'
                                size='large'
                                fullWidth
                                onClick={() => {
                                    navigate(-1)
                                }}
                            >
                                Regresar
                            </Button>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </>
    )
}

export default ClientDetail