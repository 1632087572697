import { TextField } from '@mui/material'
import React, { useState } from 'react'
import useSecurity from '../../hooks/useSecurity';

import LoadingButton from '@mui/lab/LoadingButton';
import routes from '../../routes/routes';
import { Navigate } from 'react-router-dom';

import ConexsurLogo from '../../assets/logo192.png'

const Login = () => {


    const { submitLogin } = useSecurity()
    const token = localStorage.getItem('access')

    const [login, setLogin] = useState<{ email: string, password: string }>({ email: "", password: "" });
    const [loading, setLoading] = useState<boolean>(false);


    const handleSubmit = async (e: React.SyntheticEvent) => {
        e.preventDefault()
        setLoading(true);
        submitLogin(login.email, login.password)
            .then(() => setLoading(false))
            .catch(() => setLoading(false))
    }
    return (

        token ?
            <Navigate to={routes.home.route} />
            :
            <>
                <div className="login">
                    <img src={ConexsurLogo} alt='conexsur-logo' />
                    <form className="form-container" onSubmit={(e) => handleSubmit(e)}>
                        <TextField
                            name="username"
                            placeholder='Usuario'
                            fullWidth
                            className="form-input"
                            onChange={(({ target }) => setLogin({ ...login, email: target.value }))}
                        />
                        <TextField
                            name="password"
                            placeholder='contraseña'
                            type="password"
                            fullWidth
                            className="form-input"
                            onChange={(({ target }) => setLogin({ ...login, password: target.value }))}
                            autoComplete="current-password"
                        />
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            size='large'
                            type='submit'
                            fullWidth
                            disabled={loading}
                            loading={loading}
                        >
                            Iniciar sesión
                        </LoadingButton>
                    </form>

                </div>
            </>

    )
}

export default Login
