import api, { axiosNode } from '../api/axios'
import { apiUrls } from '../api/apiUrls'
import { useNavigate } from 'react-router-dom';
import routes from '../routes/routes';
import useNotify from './useNotify'
import { useDispatch } from 'react-redux';
import { setCurrentUser, setToken } from '../store/auth/authSlice';
import useConfig from './useConfig';

const useSecurity = () => {

    const navigate = useNavigate();
    const { showMessage } = useNotify()
    const dispatch = useDispatch();
    const { loadConfigs } = useConfig();


    const submitLogin = async (email: string, password: string): Promise<any> => {
        const credentials = {
            email,
            clave: password
        }
        return axiosNode.post(apiUrls.security.PostTokenCreate, credentials)
            .then((res) => {
                if (res.request.status === 200) {
                    dispatch(setToken(res.data.token))
                    dispatch(setCurrentUser(res.data.token))
                    localStorage.setItem('refresh', res.data.refresh)
                    localStorage.setItem('access', res.data.token)
                    loadConfigs()
                    navigate(routes.home.route)
                } else {
                    throw res.data.message
                }
            })
            .catch((err) => {
                console.log(err)
                showMessage(err.response.data.message, 'error')
            })
    }

    const handleLogout = () => {
        localStorage.removeItem('refresh')
        localStorage.removeItem('currentUser')
        localStorage.removeItem('access')
        dispatch(setToken(null))
        navigate(routes.login.route)

    }


    return {
        submitLogin,
        handleLogout,
    }

}

export default useSecurity;
