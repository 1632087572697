import React, { useState, SyntheticEvent, ChangeEvent } from 'react';
import { Autocomplete, FormHelperText } from '@mui/material';
import TextField from '@mui/material/TextField';
import useClients from '../../hooks/useClients';
import { IClient } from '../../api/models/response/IClientResponse';

interface UserAutocompleteProps {
    value: string;
    onSelectUser: (user: IClient | null) => void;
    error?: boolean;
}

const UserAutocomplete: React.FC<UserAutocompleteProps> = ({ onSelectUser, value, error }) => {
    const [options, setOptions] = useState<IClient[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { searchClients } = useClients();

    const handleSearchUser = async (val: string, reason: string) => {
        if (reason === 'clear') {
            onSelectUser(null);
        }
        if (reason === 'input') {
            setIsLoading(true);
            let query: any = {};
            if (/^\d+$/.test(val)) {
                query = { cliente: parseInt(val, 10) };
            } else {
                query = { rso: val };
            }
            try {
                const response = await searchClients(query);
                setOptions(response);
                setIsLoading(false);
            } catch (error) {
                setOptions([]);
            }
        }
    };

    const handleSelectUser = (_event: ChangeEvent<{}>, user: IClient | null) => {
        if (user) {
            onSelectUser(user);
        }
    };

    return (
        <>
            <Autocomplete
                options={options}
                onChange={handleSelectUser}
                filterOptions={(x) => x}
                onInputChange={(_event: SyntheticEvent<Element, Event>, inputVal: string, reason: string) =>
                    handleSearchUser(inputVal, reason)
                }
                renderOption={(props, option) => (
                    <li {...props} key={option.cliente} value={option.cliente}>
                        {`${option.cliente}-${option.rso}`}
                    </li>
                )}
                sx={(!value && error) ? { border: '1px solid #f27b35', borderRadius: '5px' } : {}}
                clearOnBlur={false}
                loading={isLoading}
                value={options.find((option) => option.cliente === value) || null}
                getOptionLabel={(option) => `${option.cliente}`}
                renderInput={(params) => <TextField {...params} label="Buscar Cliente" variant="outlined" />}
            />
            {(!value && error) && <FormHelperText sx={{ color: '#f27b35' }}>El cliente es requerido</FormHelperText>}
        </>
    );
};

export default UserAutocomplete;

