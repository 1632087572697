import React, { useEffect, useMemo, useState } from 'react'
import {
    Autocomplete,
    Button,
    Card,
    CardContent,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
} from '@mui/material'
import { Box } from '@mui/system'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { IRootStore } from '../../store/store'
import { useFormik } from 'formik';
import useConfig from '../../hooks/useConfig';
import * as yup from 'yup';
import ViewHeader from '../../components/layout/ViewHeader';
import { IUserRequest } from '../../api/models/request/IUserRequest'
import useUsers from '../../hooks/useUsers'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { ERoles, rolesArray } from '../../constants/users/users'

const validationSchema = yup.object({
    dni: yup.number().required('La cédula es requerida'),
    address: yup.string().required('La dirección es requerida'),
    phone: yup.string().required('El teléfono es requerido'),
    region: yup.string().required('El departamento es requerido'),
    city_id: yup.string().min(1, 'La ciudad es requerida').required('La ciudad es requerida'),
    email: yup.string().email('Ingrese un correo válido').required('El correo es requerido'),
    // group_id: yup.number().min(1, 'El rol es requerido').required('El rol es requerido'),
    username: yup.string().required('El nombre de usuario es requerido'),
    clave: yup.string().when('groups', { is: (val: number) => val === 7, then: yup.string().required('Contaseña requerida') })
})

type Props = {}

const UsersForm = (props: Props) => {
    const { id } = useParams()
    const { createUser, getUserById, updateUser } = useUsers()
    const currentUser = useSelector((state: IRootStore) => state.auth.currentUser);
    const navigate = useNavigate()
    const { getCitiesFromRegion } = useConfig()
    const regions = useSelector((state: IRootStore) => state.config.regions)
    const [cities, setCities] = useState<Array<any>>([]);
    const [citiesAreLoading, setCitiesAreLoading] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState(false);

    const initialValues = {
        dni: 0,
        address: '',
        city_id: '',
        phone: '',
        email: '',
        username: '',
        region: '',
        group_id: 7,
        clave: ''
    }
    // Handlers
    const handleSubmit = async (user: IUserRequest) => {
        if (id) { updateUser(user, id) } else { createUser(user) }
    }

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleGetUserById = async () => {
        if (id) {
            getUserById(id)
                .then(res => {
                    formik.setValues(res)
                })
        }

    }

    const formik = useFormik({
        initialValues,
        onSubmit: (values) => {
            handleSubmit(values)
        },
        validationSchema: validationSchema,
        enableReinitialize: true
    })

    const handleGetCities = (region: string, city: string) => {
        setCitiesAreLoading(true)
        getCitiesFromRegion(region, city)
            .then(res => {
                setCities(res.map((c: any) => ({ id: c._id, label: c.name })))
                setCitiesAreLoading(false)
            })
            .catch((err) => setCitiesAreLoading(false))

    }

    useEffect(() => {
        if (formik.values.region && formik.values.region !== '') {
            handleGetCities(formik.values.region, '')
        } else {
            setCities([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.region]);

    useEffect(() => {
        handleGetUserById()
        // eslint-disable-next-line react-hooks/exhaustive-deps

    }, [id])


    const getRegionNameById = useMemo(() => {
        return (id: string) => {
            const foundItem = regions.find(item => item._id === id);
            return foundItem ? foundItem.name : null;
        };
    }, [regions]);

    const getCityNameById = useMemo(() => {
        return (id: string) => {
            const foundItem = cities.find(item => item.id === id);
            return foundItem ? foundItem.label : null;
        };
    }, [cities])


    return (
        <>
            <ViewHeader text={id ? "Editar Usuario" : "Nuevo usuario"} />
            <Card>
                <CardContent>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={2} mt={4}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    variant='outlined'
                                    name='dni'
                                    onChange={formik.handleChange}
                                    value={formik.values.dni || ''}
                                    type='number'
                                    label='Cédula'
                                    fullWidth
                                    error={formik.touched.dni && Boolean(formik.errors.dni)}
                                    helperText={(formik.touched.dni && formik.errors.dni) || " "}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    variant='outlined'
                                    label='Dirección'
                                    name='address'
                                    onChange={formik.handleChange}
                                    value={formik.values.address}
                                    error={formik.touched.address && Boolean(formik.errors.address)}
                                    helperText={(formik.touched.address && formik.errors.address) || " "}
                                    fullWidth />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    variant='outlined'
                                    label='Teléfono'
                                    name='phone'
                                    onChange={formik.handleChange}
                                    value={formik.values.phone}
                                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                                    helperText={(formik.touched.phone && formik.errors.phone) || " "}
                                    fullWidth />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl variant='outlined' fullWidth>
                                    <Autocomplete
                                        fullWidth
                                        value={getRegionNameById(formik.values.region)}
                                        isOptionEqualToValue={(option, value) => option.label === value}
                                        options={regions.map(reg => ({ id: reg._id, label: reg.name }))}
                                        onChange={(e, value) => {
                                            formik.setFieldValue('region', value?.id)
                                        }}
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                label='Region'
                                                name='region'
                                                error={formik.touched.region && Boolean(formik.errors.region)}
                                                helperText={(formik.touched.region && formik.errors.region) || " "}
                                            />
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl variant='outlined' fullWidth>
                                    <Autocomplete
                                        loading={citiesAreLoading}
                                        value={getCityNameById(formik.values.city_id)}
                                        options={cities}
                                        onChange={(e, value) => {
                                            formik.setFieldValue('city_id', value?.id)
                                        }}
                                        isOptionEqualToValue={(option, value) => option.label === value}
                                        disabled={cities.length === 0}
                                        renderInput={(params) =>
                                            <TextField {...params}
                                                label='Ciudad'
                                                name='city_id'
                                                error={formik.touched.city_id && Boolean(formik.errors.city_id)}
                                                helperText={(formik.touched.city_id && formik.errors.city_id) || " "}
                                                disabled={cities.length === 0}
                                                onChange={(e) => {
                                                    handleGetCities(formik.values.region, e.target.value)
                                                }}
                                            />}
                                        renderOption={(props, option) => (
                                            <Box component="li" {...props} key={option.id}>
                                                {option.label}
                                            </Box>
                                        )
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    variant='outlined'
                                    label='Correo electrónico'
                                    name='email'
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={(formik.touched.email && formik.errors.email) || " "}
                                    fullWidth />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    variant='outlined'
                                    label='Nombre de usuario'
                                    name='username'
                                    onChange={formik.handleChange}
                                    value={formik.values.username}
                                    error={formik.touched.username && Boolean(formik.errors.username)}
                                    helperText={(formik.touched.username && formik.errors.username) || " "}
                                    fullWidth />
                            </Grid>
                            {
                                currentUser?.group_id === ERoles.ADMINISTRACION &&
                                <>
                                    <Grid item xs={12} md={6}>
                                        <FormControl fullWidth>
                                            <InputLabel id="select-label-role">Rol</InputLabel>
                                            <Select
                                                labelId="select-label-role"
                                                label="Rol"
                                                name='group_id'
                                                value={formik.values.group_id || ''}
                                                onChange={formik.handleChange}
                                                error={formik.touched.group_id && Boolean(formik.errors.group_id)}
                                            >
                                                {
                                                    rolesArray.map((role, i) => (
                                                        <MenuItem key={`${role.id}-${i}`} value={role.id}>{role.name}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                            <FormHelperText
                                                sx={{ color: 'error.main' }}>{(formik.touched.group_id && formik.errors.group_id) || " "}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControl variant="outlined" fullWidth >
                                            <InputLabel htmlFor="outlined-adornment-password">Contraseña</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-password"
                                                label="Contraseña"
                                                type={showPassword ? 'text' : 'password'}
                                                fullWidth
                                                name='clave'
                                                onChange={formik.handleChange}
                                                value={formik.values.clave}
                                                error={formik.touched.clave && Boolean(formik.errors.clave)}
                                                autoComplete='off'
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge="end"
                                                        >
                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                            <FormHelperText
                                                sx={{ color: 'error.main' }}>{(formik.touched.clave && formik.errors.clave) || " "}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                </>
                            }

                            <Grid item xs={12}>
                                <Box display="flex" justifyContent={{ md: 'flex-end', sm: 'center' }} width="100%">
                                    <Box width={{ sm: '50%', md: '20%' }} mr={1}>
                                        <Button
                                            variant='outlined'
                                            color='primary'
                                            size='large'
                                            fullWidth
                                            onClick={() => {
                                                navigate(-1)
                                            }}
                                        >
                                            Cancelar
                                        </Button>
                                    </Box>
                                    <Box width={{ sm: '50%', md: '20%' }}>
                                        <Button variant='contained' color='primary' size='large' fullWidth
                                            type='submit'>
                                            {id ? "Guardar" : "Crear"}
                                        </Button>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </>
    )
}

export default UsersForm