import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material'
import { GridCloseIcon } from '@mui/x-data-grid';
import React from 'react'

type Props = {
    isOpen: boolean;
    name: string;
    handleClose: () => void;
    data: any
}

const UploadLogModal = (props: Props) => {
    const { isOpen, name, handleClose, data } = props;
    const optimizedData = data.data.slice(0, 100);
    return (
        <Dialog
            open={isOpen}
            onClose={() => handleClose()}
        >
            <DialogTitle sx={{ marginRight: 4, }}>Registro de carga {name} - {data.status}</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <GridCloseIcon />
            </IconButton>
            <DialogContent>
                {
                    optimizedData.map((element: any) => (
                        element.error ?
                            <Grid item container xs={12} key={element.acum}>
                                <Grid item xs={4} sx={{ border: '1px solid black', p: 1 }} >
                                    <Typography variant='caption' >acum:{element.acum}</Typography>
                                </Grid>
                                <Grid item xs={4} sx={{ border: '1px solid black', p: 1 }}>
                                    <Typography variant='caption' >nombre:{element.nombre}</Typography>
                                </Grid>
                                <Grid item xs={4} sx={{ border: '1px solid black', p: 1 }}>
                                    <Typography variant='caption' color='error'>{element.error}</Typography>
                                </Grid>
                            </Grid>

                            :
                            <Grid item xs={12} sx={{ border: '1px solid black', p: 2 }} key={element}>
                                <Typography variant='caption'>{element}</Typography>
                            </Grid>
                    )
                    )
                }
            </DialogContent>
        </Dialog>
    )
}

export default UploadLogModal