
export enum ERoles {
    TODOS = 0,
    BODEGA = 1,
    LOGISTICA = 2,
    CARTERA = 3,
    VENDEDOR = 4,
    FACTURACION = 5,
    COMPRADOR = 6,
    ADMINISTRACION = 7
};

export const rolesArray = Object.entries(ERoles)
    .filter(([name, id]) => typeof id === 'number' && id !== ERoles.TODOS) // Excluir TODOS
    .map(([name, id]) => ({
        name,
        id
    }));

export const roleById = (id: number): string => {
    const role = rolesArray.find(role => role.id === id);
    return role ? role.name : '';
}