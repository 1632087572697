import {createSlice} from "@reduxjs/toolkit";

export interface IConfigStore {
    cities: Array<any>,
    regions: Array<any>,
    roles: Array<any>
}

const initialState = {
    cities: [],
    regions: [],
    roles: []
}

export const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        setCities: (state, action) => {
            state.cities = action.payload
        },
        setRegions: (state, action) => {
            state.regions = action.payload
        },
        setRoles: (state, action) => {
            state.roles = action.payload
        }
    }
})

export const {setCities, setRegions, setRoles} = configSlice.actions;

export default configSlice.reducer;