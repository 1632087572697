import React, { useState, useEffect } from 'react';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { DataGrid, esES, GridColDef } from '@mui/x-data-grid';
import routes from '../../routes/routes';
import useOrders from '../../hooks/useOrders';
import EditIcon from '@mui/icons-material/Edit';

type Order = {
    id: number;
    firstName: string;
    document: string;
    date: number;
    total: number;
};

const Orders = () => {
    const { getOrders } = useOrders();
    const [ordersRows, setOrdersRows] = useState<Order[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchOrders = async () => {
            const orders = await getOrders();
            setOrdersRows(orders);
        };

        fetchOrders();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns: GridColDef[] = [
        {
            field: 'dateCreated',
            headerName: 'Fecha',
            type: 'number',
            flex: 0.5,
            headerAlign: 'left',
            align: 'left',
            renderCell: (params) => {
                const date = new Date(params.row.dateCreated);
                return date.toLocaleDateString('es-CO');
            }
        },
        {
            field: 'documentType',
            headerName: 'Tipo',
            flex: 1,
            editable: true,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'client.rso',
            headerName: 'Cliente',
            flex: 1,
            renderCell: (params) => {
                return params.row.client.rso;
            }
        },
        {
            field: 'client.cliente',
            headerName: 'Cédula',
            flex: 1,
            renderCell: (params) => {
                return params.row.client.cliente;
            }
        },
        {
            field: 'clientMail',
            headerName: 'Correo',
            flex: 1
        },
        {
            field: 'stateOrdern',
            headerName: 'Estado',
            flex: 1,
        },
        {
            headerName: 'Acciones',
            field: 'editButton',
            renderCell: (params) => {
                return (
                    <IconButton aria-label="" onClick={() =>
                        navigate(params.row._id)
                    }>
                        <EditIcon sx={{ color: 'white.main' }} />
                    </IconButton>
                );
            }
        }

        // {
        //     field: 'total',
        //     headerName: 'Total',
        //     type: 'number',
        //     flex: 1,
        // }
    ];

    return (
        <>
            <Typography variant="h2">Ordenes</Typography>
            <Box display={'flex'} justifyContent={'flex-end'} width="100" mt={2} mb={4}>
                <Link to={routes.newOrder.route}>
                    <Button variant="contained" color="secondary">
                        Crear Orden
                    </Button>
                </Link>
            </Box>
            <Box sx={{ height: 400, width: '100%' }}>
                <DataGrid
                    className="conexsur-data-grid"
                    getRowId={(row) => row._id}
                    rows={ordersRows}
                    columns={columns}
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                />
            </Box>
        </>
    );
};

export default Orders;

