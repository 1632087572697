/* eslint-disable react-hooks/exhaustive-deps */
import { Autocomplete, TextField } from '@mui/material'
import { GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import useProducts from '../../hooks/useProducts'

interface Props {
    rowParams: GridRenderCellParams<any, number>
}

const OrdersAutocomplete = ({ rowParams }: Props) => {
    const [keyword, setKeyword] = useState<string>("");
    const [options, setOptions] = useState<any>([]);
    const { findProduct } = useProducts()
    const apiRef = useGridApiContext();

    useEffect(() => {
        findProduct(keyword)
            .then((res) => setOptions(res))
    }, [keyword]);

    return (
        <Autocomplete
            options={options}
            sx={{ width: '100%' }}
            onChange={(event, newValue: any) => {
                apiRef.current.updateRows([{ id: rowParams.id, description: newValue.description, colorIdOptions: newValue.color_id }])
                apiRef.current.setEditCellValue({ id: rowParams.id, field: rowParams.field, value: newValue.description })
            }}
            renderInput={(params) => <TextField {...params} onChange={(e) => setKeyword(e.target.value)} />}
            getOptionLabel={(option) => option.description}

        />
    )
}

export default OrdersAutocomplete