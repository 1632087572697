import { CircularProgress, Typography } from '@mui/material'
import logo from '../../assets/logo192.png'

type props = {
    text: string,
    hasLogo?: boolean
}
const AppLoader = ({ text, hasLogo = true }: props) => {
    return (
        <div className='app-loader'>
            {hasLogo && <img src={logo} alt='logo' />}
            <Typography variant='h5'>{text}</Typography>
            <CircularProgress className='spinner' size={50} thickness={2} value={100} />
        </div>
    )
}

export default AppLoader