import {createSlice} from "@reduxjs/toolkit";

export interface IProductsStore {
    colors: Array<any>,
    sizes: Array<any>,
    brands: Array<any>
}

const initialState: IProductsStore = {
    colors: [],
    sizes: [],
    brands: []
}

export const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        setColors: (state, action) => {
            state.colors = action.payload
        },
        setSizes: (state, action) => {
            state.sizes = action.payload
        },
        setBrands: (state, action) => {
            state.brands = action.payload
        },
    }
})

export const {setColors, setSizes, setBrands} = productsSlice.actions;

export default productsSlice.reducer;