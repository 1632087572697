// @ts-nocheck
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    Autocomplete,
    Button,
    Checkbox,
    FormControl,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import useDebounce from '../../hooks/utils/useDebounce';
import { axiosNode } from '../../api/axios';
import useNotify from '../../hooks/useNotify';
import { array } from 'yup';

// Define types
type Shoe = {
    _id: string;
    size_id: { name: string };
    color_id: { name: string };
    brand_id: { name: string };
};

type Reference = {
    id: string;
    name: string;
    quantity: string;
};

type Filters = {
    productBrand: string;
    productColor: string;
};

type SizesTableProps = {
    rows: Shoe[];
    addShoe: (shoe: { id: string; color: string; brand: string; sizes: Reference[] }) => void;
    resetFilters: () => void;
};

type OrderShoesAdderProps = {
    addShoe: any;
    addedShoes: array<Shoe>;
};


const getShoes = async (filters: Filters): Promise<Shoe[]> => {
    const query = {
        query: { description: filters.productBrand },
        values: { productBrand: '', productColor: filters.productColor, productSize: filters.productSize },
    };
    const response = await axiosNode.post('productshoe/1/50', query);
    return response.data.zapatos;
};

// SizesTable component
const SizesTable: React.FC<SizesTableProps> = ({ rows, addShoe, resetFilters }) => {
    const [references, setReferences] = useState<Reference[]>(rows.map((row) => ({
        _id: row._id,
        brand: row.brand_id.name,
        color: row.color_id.name,
        size: row.size_id.name,
        quantity: '',
        prices: row.prices,
        shoeId: row._id,
        amount: row.amount
    })));
    const [excededInventory, setExcededInventory] = useState(false);
    const [submitButtonEnabled, setSubmitButtonEnabled] = useState(false);

    useEffect(() => {
        const idsInRefs = new Set(references.map(item => item._id));
        const newElements = rows.filter((ref) => !idsInRefs.has(ref._id));


        setReferences((state) => [...state, ...newElements.map((row) => ({
            _id: row._id,
            brand: row.brand_id.name,
            color: row.color_id.name,
            size: row.size_id.name,
            quantity: '',
            prices: row.prices,
            shoeId: row._id,
            amount: row.amount
        }))]);
    }, [rows]);

    useEffect(() => {
        const quantitiesExcedInventory = references.some((ref) => ref.amountPeers > ref.amount)
        setExcededInventory(quantitiesExcedInventory);
        const allQuantitiesEntered = references.every((ref) => ref.amountPeers && ref.amountPers !== '');
        setSubmitButtonEnabled(allQuantitiesEntered && !quantitiesExcedInventory);
    }, [references, rows]);

    const handleQuantityChange = (id: string, value: string) => {
        const updatedReferences = references.map((ref) => (ref._id === id ? { ...ref, amountPeers: value } : ref));
        setReferences(updatedReferences);
    };



    return (
        <>
            <DataGrid
                rows={rows}
                getRowId={(row) => row._id}
                hideFooter
                columns={[
                    {
                        field: 'size_id',
                        headerName: 'Talla',
                        flex: 1,
                        valueGetter: (params) => params.row.size_id?.name,
                        headerAlign: 'center',
                        align: 'center',
                    },
                    {
                        field: 'amount',
                        headerName: 'Cantidad Inventario',
                        headerAlign: 'center',
                        align: 'center',
                        type: 'number',
                        flex: 1,
                    },
                    {
                        field: 'quantity',
                        headerName: 'Cantidad Pedido',
                        headerAlign: 'center',
                        type: 'number',
                        renderCell: (params) => {
                            return (
                                <TextField
                                    fullWidth
                                    type="number"
                                    onChange={(e) => handleQuantityChange(params.row._id, e.target.value)}
                                    error={references.find((ref) => ref._id === params.row._id)?.amountPeers > params.row.amount}
                                />
                            );
                        },
                        flex: 1,
                    },
                ]}
                pageSize={5}
                rowsPerPageOptions={[5]}
                disableSelectionOnClick
                autoHeight
                sx={{
                    '& .MuiDataGrid-cell:hover': {
                        color: 'primary.main',
                        cursor: 'pointer',
                    },
                }}
            />
            {
                excededInventory && (
                    <Box display="flex" justifyContent="flex-start">
                        <Typography variant="h6" sx={{ mt: 2, color: 'red' }}>
                            La cantidad en pedido excede la cantidad en inventario
                        </Typography>
                    </Box>
                )
            }
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    variant="contained"
                    disabled={!submitButtonEnabled}
                    sx={{ my: 2 }}
                    onClick={() => {
                        addShoe(references);
                        setReferences([]);
                        resetFilters();
                    }}
                >
                    Añadir
                </Button>
            </Box>
        </>
    );
};

// OrderShoesAdder component
const OrderShoesAdder: React.FC<OrderShoesAdderProps> = ({ addShoe, addedShoes }) => {
    const [shoes, setShoes] = useState<Shoe[]>([]);
    const [colors, setColors] = useState<string[]>([]);
    const [query, setQuery] = useState('');
    const [result, setResult] = useState<Shoe[]>([]);
    const [filters, setFilters] = useState<Filters>({ productBrand: '', productColor: '' });
    const [selectedSizes, setselectedSizes] = useState<string[]>([]);
    const debouncedSearch = useDebounce(query);
    const [noShoes, setNoShoes] = useState(false);
    const { showMessage } = useNotify();

    const handleShoes = useCallback(async (brand: string, shoeFilters: Filters) => {
        try {
            const response = await getShoes(brand ? { ...shoeFilters, productSize: '', productBrand: brand } : { ...shoeFilters, productSize: '' });

            if (response.length > 0) {
                setNoShoes(false);
                setShoes(response);

                if (shoeFilters.productColor === '') {
                    const uniqueColors = [...new Set(response.map((shoe) => shoe.color_id.name))];
                    setColors(uniqueColors);
                }
            } else {
                if (Object.values(filters).every((value) => typeof value === 'string' && value.trim() !== '')) {
                    setNoShoes(true);
                }
            }
        } catch (error) {
            // Handle error appropriately, e.g., show an error message
            showMessage('Error al obtener los zapatos', 'error');
        }
    }, [filters, setColors, setNoShoes, setShoes, showMessage]);

    useEffect(() => {
        if (!isResultReady()) {
            handleShoes(query, filters);
        } else {
            setResult(shoes.filter((el) => selectedSizes.indexOf(el.size_id.name) > -1));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearch, filters, selectedSizes]);

    const isResultReady = useCallback(() => {
        return Object.values(filters).every((value) => typeof value === 'string' && value.trim() !== '') && selectedSizes.length > 0 && result;
    }, [filters, selectedSizes, result]);

    function resetFilters() {
        setFilters({ productBrand: '', productColor: '' });
        setQuery('');
        setShoes([]);
        setColors([]);
        setselectedSizes([]);
    }

    function removeDuplicates(arr: Shoe[]): Shoe[] {
        const uniqueBrands = new Set<string>();

        return arr.filter((item) => {
            const brandName = item.brand_id.name;

            if (!uniqueBrands.has(brandName)) {
                uniqueBrands.add(brandName);
                return true;
            }
            return false;
        });
    }

    const handleChangeSizes = (event: any) => {
        const {
            target: { value },
        } = event;
        setselectedSizes(typeof value === 'string' ? value.split(',') : value);
    };

    return (
        <>
            <Typography variant="h5" sx={{ mb: 2 }}>
                Zapatos
            </Typography>
            <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={4}>
                    <Autocomplete
                        id="brand"
                        filterOptions={(x) => x}
                        options={removeDuplicates(shoes).map((shoe) => ({ id: shoe._id, label: `${shoe.brand_id.name}/${shoe.description}`, ...shoe }))}
                        value={filters.productBrand || null}
                        renderOption={(props, option) => (
                            <li
                                {...props}
                                style={{
                                    borderBottom: "1px solid #bdbdbd",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "flex-start",
                                    alignItems: "flex-start",
                                }}
                            >
                                <span>{option.brand_id.name}</span>
                                <span style={{ color: "#bdbdbd" }}>{option.description}</span>
                            </li>
                        )}
                        isOptionEqualToValue={(option, value) => option.brand_id.name === value}
                        onChange={(e, value, reason) => {
                            if (reason === "clear") {
                                resetFilters();
                                return;
                            }
                            setFilters({ ...filters, productBrand: value.brand_id.name });
                            setQuery("");
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                label="Descripción"
                                onChange={(e) => {
                                    setQuery(e.target.value);
                                }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <InputLabel id="select-color">Color</InputLabel>
                        <Select
                            labelId="select-color"
                            id="demo-simple-select"
                            fullWidth
                            value={filters.productColor}
                            label="Color"
                            disabled={shoes.length === 0}
                            onChange={(e, value) => {
                                setFilters({ ...filters, productColor: e.target.value });
                                setselectedSizes([]);
                            }}
                        >
                            {colors.length > 0 &&
                                colors.map((color, i) => (
                                    <MenuItem key={color} value={color}>
                                        {color}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <InputLabel id="select-size">Talla</InputLabel>
                        <Select
                            labelId="select-size"
                            label="Talla"
                            multiple
                            value={selectedSizes}
                            onChange={handleChangeSizes}
                            renderValue={(selected) => selected.join(", ")}
                            disabled={shoes.length === 0 || filters.productColor === "" || noShoes}
                        >
                            {shoes.length > 0 &&
                                shoes.map((size, i) => (
                                    <MenuItem
                                        key={size.size_id._id}
                                        value={size.size_id.name}
                                        disabled={addedShoes.some((shoe) => shoe._id === size._id || shoe?.shoePrriceId === size._id)}
                                    >
                                        <Checkbox
                                            checked={selectedSizes.indexOf(size.size_id.name) > -1}
                                            disabled={addedShoes.some((shoe) => shoe._id === size._id || shoe?.shoePrriceId === size._id)} />
                                        <ListItemText primary={size.size_id.name} />
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="flex-end">
                    <Button variant="outlined" color="warning" onClick={resetFilters}>
                        Limpiar resultado
                    </Button>
                </Grid>
            </Grid >
            {noShoes && (
                <Box display="flex" justifyContent="center">
                    <Typography variant="h6" sx={{ mt: 2 }}>
                        No se encontraron zapatos con esos filtros. Intente con otros.
                    </Typography>
                </Box>
            )
            }
            {isResultReady() && shoes.length > 0 && result.length > 0 && <SizesTable rows={result} addShoe={addShoe} resetFilters={resetFilters} />}
        </>
    );
};

export default OrderShoesAdder;
