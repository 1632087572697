import { Box, Card, CardContent, Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import FileUpload from '../../components/inventory/FileUpload'
import { apiUrls } from '../../api/apiUrls'
import ConfigCreator from '../../components/inventory/ConfigCreator'
import useProducts from '../../hooks/useProducts'
import { useSelector } from 'react-redux'
import { IRootStore } from '../../store/store'
import useUsers from '../../hooks/useUsers'
import { ERoles } from '../../constants/users/users'

const inventarioFile = require('../../assets/templates/INVENTARIO.xlsx');
const listaFile = require('../../assets/templates/LISTA DE PRECIOS.xlsx');

type Props = {}

const Inventory = (props: Props) => {

    const {
        getProductsColors,
        getProductsSizes,
        getProductsBrands,
        createColor,
        createSize,
        createBrand
    } = useProducts();
    const { hasRoleAccess } = useUsers();
    const colors = useSelector((state: IRootStore) => state.products.colors)
    const sizes = useSelector((state: IRootStore) => state.products.sizes)
    const brands = useSelector((state: IRootStore) => state.products.brands)

    interface IFileType {
        api: string,
        name: string,
        file: any,
        fileName: string,
    }

    const fileTypes: Array<IFileType> = [
        {
            api: apiUrls.products.PostUploadInventory,
            name: 'Item',
            file: inventarioFile,
            fileName: 'INVENTARIO.xlsx'
        },
        {
            api: apiUrls.products.PostUploadPrice,
            name: 'Precio',
            file: listaFile,
            fileName: 'LISTA DE PRECIOS.xlsx'
        }
    ]
    const fetchInventoryConfigs = async () => {
        await getProductsColors();
        await getProductsSizes();
        await getProductsBrands();
    }
    useEffect(() => {
        fetchInventoryConfigs()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <Typography variant='h2'>
                Inventario
            </Typography>
            {
                hasRoleAccess([ERoles.ADMINISTRACION, ERoles.LOGISTICA, ERoles.FACTURACION]) &&
                fileTypes.map((type: IFileType, i: number) => (
                    <Box mt={2} key={i}>
                        <Card>
                            <CardContent>
                                <Typography variant='h5'>
                                    {type.name}
                                </Typography>
                                <FileUpload apiUrl={type.api} file={type.file} fileName={type.fileName} name={type.name} />
                            </CardContent>
                        </Card>
                    </Box>
                ))
            }
            <Grid container spacing={2} sx={{ mb: 4 }} >
                <Grid item xs={12} sm={6} md={4}>
                    <ConfigCreator
                        title="Colores"
                        rows={colors}
                        handleCreate={createColor}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <ConfigCreator
                        title="Tallas"
                        rows={sizes}
                        handleCreate={createSize}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <ConfigCreator
                        title="Referencias"
                        rows={brands}
                        handleCreate={createBrand}
                    />
                </Grid>

            </Grid>


        </>
    )
}

export default Inventory