import { useEffect, useState } from 'react';
// @mui
import { Box, Button, IconButton } from '@mui/material'
import { DataGrid, esES, getGridStringOperators, GridColDef, GridFilterModel } from '@mui/x-data-grid'
import VisibilityIcon from '@mui/icons-material/Visibility';
// project components
import ClientsUploadModal from '../../components/clients/ClientsUploadModal';
// project settings
import { IUser } from '../../api/models/response/IUserResponse'
import ViewHeader from '../../components/layout/ViewHeader';
import useClients from '../../hooks/useClients';
import { useNavigate } from 'react-router-dom';
import routes from '../../routes/routes';


type Props = {}
const initialPagination = {
    page: 0,
    pageSize: 10,
}
const Clients = (props: Props) => {
    const { getClients, clients, isLoading, pageSize } = useClients();
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [pagination, setPagination] = useState<any>(initialPagination);

    const navigate = useNavigate();

    useEffect(() => {
        getClients({}, pagination)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination]);

    const onFilterChange = (filterModel: GridFilterModel) => {
        const filter = filterModel.items[0]
        getClients({ query: { [filter.field]: filter.value } }, initialPagination)
        setPagination(initialPagination)
    }

    const columns: GridColDef[] = [
        {
            field: 'rso',
            headerName: 'Razón Social',
            headerAlign: 'center',
            align: 'left',
            flex: 1,
            filterOperators: getGridStringOperators()
                .filter((operator) => operator.value === "equals"),
        },
        {
            field: 'nce',
            headerName: 'Documento',
            flex: 1
        },
        {
            field: 'di1',
            headerName: 'Dirección',
            flex: 1
        },
        {
            headerName: 'Acciones',
            headerAlign: 'center',
            flex: 0.5,
            align: 'center',
            field: 'actions',
            renderCell: (params) => (
                <>
                    <IconButton aria-label="" onClick={() =>
                        navigate(routes.viewClient.route.replace(':id', params.row._id))
                    }>
                        <VisibilityIcon sx={{ color: 'white.main' }} />
                    </IconButton>
                </>
            )
        }
    ]
    return (
        <>
            <ClientsUploadModal isOpen={modalIsOpen} onClose={() => setModalIsOpen(false)} />
            <ViewHeader text='Clientes' />
            <Box display={'flex'} justifyContent={'flex-end'} width='100' mb={4}>
                <Button
                    variant='contained'
                    color='success'
                    sx={{ mr: 1 }}
                    onClick={() => setModalIsOpen(true)}
                >
                    Subir archivo
                </Button>
                {/* <Link to={routes.viewClient.route}>
                    <Button variant='contained' color='secondary'>
                        Crear Cliente
                    </Button>
                </Link> */}
            </Box>
            <Box sx={{
                height: 400,
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                {
                    <DataGrid
                        className='conexsur-data-grid'
                        rows={clients.map((user: IUser, i: number) => ({ ...user, id: i }))}
                        columns={columns}
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        loading={isLoading}
                        disableRowSelectionOnClick
                        filterMode='server'
                        onFilterModelChange={onFilterChange}
                        paginationMode="server"
                        paginationModel={pagination}
                        pageSizeOptions={[10]}
                        rowCount={pageSize}
                        onPaginationModelChange={setPagination}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                        }
                    />
                }
            </Box>
        </>
    )
}

export default Clients;