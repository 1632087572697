import { useState } from "react";
import { axiosNode } from "../api/axios";
import { apiUrls } from "../api/apiUrls";
import { IBaseResponse } from "../api/models/response/IGenericResponse";


const useClients = () => {
    const [clients, setClients] = useState<any>([]);
    const [pageSize, setPageSize] = useState<number>(0);
    const [isLoading, setisLoading] = useState<boolean>(false);

    const getClients = async (query: any, pagination: any) => {
        const currentPage = pagination.page + 1;
        axiosNode.post(`${apiUrls.clients.GetClients}/${currentPage}/${pagination.pageSize}`, query)
            .then(res => {
                if (res.status !== 200) throw Error
                setPageSize(res.data.totalCount)
                setClients(res.data.results)
                setisLoading(false)
            })
            .catch(err => {
                setClients([])
                setisLoading(false)
            }
            )

    }
    const getClientById = async (id: string) => {
        return await axiosNode.get(`${apiUrls.clients.GetClientById}/${id}`)
            .then(res => {
                return res.data
            })
            .catch(err => {
                return err
            }
            )
    }
    const uploadClients = async (file: any) => {
        let formData = new FormData();
        formData.append("file", file);
        setisLoading(true)
        return await axiosNode.post<IBaseResponse>(apiUrls.clients.PostUploadClients, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(res => {
                setisLoading(false)
                return res
            })
            .catch(err => {
                setisLoading(false)
                return err
            }
            )
    }

    const searchClients = async (query: any) => {
        return axiosNode.post(`${apiUrls.clients.GetClients}/1/10`, { query })
            .then(res => {
                if (res.status !== 200) throw Error
                return res.data.results
            })
            .catch(err => {
                setClients([])
                setisLoading(false)
            }
            )

    }
    return {
        getClients,
        clients,
        isLoading,
        pageSize,
        uploadClients,
        getClientById,
        searchClients
    }

}

export default useClients;