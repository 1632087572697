import React from 'react'
import {
    Box,
    Divider,
    Drawer as MuiDrawer,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography
} from '@mui/material'
import { Link } from 'react-router-dom'
import routes from '../../routes/routes';
import { useSelector } from 'react-redux';
import { IRootStore } from '../../store/store';

type Props = {
    isOpen: boolean,
    handleClose: () => void
}

const Drawer = (props: Props) => {
    const {
        isOpen,
        handleClose
    } = props

    const currentUser = useSelector((state: IRootStore) => state.auth.currentUser)

    const showOnDrawer = (route: any) => {
        if (route.showOnDrawer) {
            if (route.access.includes(currentUser?.group_id) || route.access.includes(0)) {
                return true
            }
            return false
        }
        return false
    }
    return (
        <MuiDrawer
            open={isOpen}
            onClose={() => handleClose()}
        >
            <Box sx={{ width: 250 }}>
                <Box display='flex' justifyContent='center' my={1}>
                    <Typography variant='h5'>Conexsur</Typography>
                </Box>
                <Divider variant='middle' />
                <List>
                    {Object.values(routes).map((route, i) => (
                        showOnDrawer(route) &&
                        <Link to={route.route} key={`${route.name}-${i}`} onClick={handleClose}>
                            <ListItem disablePadding>
                                <ListItemButton>
                                    <ListItemIcon>
                                        {route.icon}
                                    </ListItemIcon>
                                    <ListItemText primary={route.name} />
                                </ListItemButton>
                            </ListItem>
                        </Link>
                    ))}
                </List>
            </Box>
        </MuiDrawer>
    )
}

export default Drawer