import { useState } from 'react';
import { apiUrls } from '../api/apiUrls';
import { IBaseResponse } from '../api/models/response/IGenericResponse';
import { IUserRequest, IUserSearchRequest } from '../api/models/request/IUserRequest';
import useNotify from './useNotify';
import { useNavigate } from 'react-router-dom';
import routes from '../routes/routes';
import { axiosNode } from '../api/axios';
import { useSelector } from 'react-redux';
import { IRootStore } from '../store/store';

const useUsers = () => {

    const { showMessage } = useNotify()
    const navigate = useNavigate()
    const [users, setUsers] = useState<any>([]);
    const [pageSize, setPageSize] = useState<number>(0);
    const [isLoading, setisLoading] = useState<boolean>(false);
    const currentUser = useSelector((state: IRootStore) => state.auth.currentUser);


    const getUsers = async (query: IUserSearchRequest, page: number) => {
        // format query parameter
        if (query) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            let queryString = '';
            Object.entries(query).forEach(([key, value]) => {
                if (value !== undefined && value !== null) {
                    queryString += `${key}=${encodeURIComponent(value)}&`;
                }
            });
        }
        setisLoading(true)
        axiosNode.post(`${apiUrls.security.GetSearchUsers}/${page}`, JSON.stringify({ query: '' }))
            .then(res => {
                if (res.status !== 200) throw Error
                setPageSize(res.data.totalCount)
                setUsers(res.data.results)
                setisLoading(false)
            })
            .catch(err => {
                setUsers([])
                setisLoading(false)
            }
            )
    }
    const getUserById = async (id: string) => {
        return await axiosNode.get(`${apiUrls.security.GetUserById}/${id}`)
            .then(res => {
                const response = {
                    ...res.data,
                    region: res.data?.city_id?.region_id,
                    city_id: res.data?.city_id?._id,
                    clave: ''
                }

                return response
            })
            .catch(err => {
                return err
            }
            )
    }

    const searchUsers = async (query: IUserSearchRequest) => {
        // let queryString = '';
        // if (query) {
        //     Object.entries(query).forEach(([key, value]) => {
        //         if (value !== undefined && value !== null) {
        //             queryString += `${key}=${encodeURIComponent(value)}`;
        //         }
        //     });
        // }
        setisLoading(true)
        return axiosNode.post(`${apiUrls.security.GetSearchUsers}/10}`, JSON.stringify({
            query: query,
        }))
            .then(res => {
                if (res.status !== 200) throw Error
                setisLoading(false)
                return res.data.results
            })
            .catch(err => {
                setisLoading(false)
            }
            )
    }

    const uploadUsers = async (file: any) => {
        let formData = new FormData();
        formData.append("file", file);
        setisLoading(true)
        return await axiosNode.put<IBaseResponse>(apiUrls.security.PutUsers, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(res => {
                setisLoading(false)
                return res
            })
            .catch(err => {
                setisLoading(false)
                return err
            }
            )
    }

    const createUser = (userData: IUserRequest) => {
        axiosNode.post(apiUrls.security.PostCreateUser, userData)
            .then(response => {
                if (response.status !== 200) throw response
                showMessage('Creado', 'success')
                navigate(routes.users.route)
            })
            .catch(error => {
                showMessage('Error en la creación', 'error')
            });

    }

    const updateUser = (userData: IUserRequest, userId: string) => {
        if (!userData.clave) delete userData.clave
        axiosNode.put(`${apiUrls.security.PutUpdateUser}/${userId}`, userData)
            .then(response => {
                if (response.status !== 200) throw response
                showMessage('Actualizado', 'success')
                navigate(routes.users.route)
            })
            .catch(error => {
                showMessage('Error en la actualización', 'error')
            });

    }

    const hasRoleAccess = (routeRoles: number[]): boolean => {
        const roleIsPresent = routeRoles.filter((role: number) => role === currentUser?.group_id || role === 0).length;
        return roleIsPresent > 0;
    };


    return {
        getUsers,
        users,
        isLoading,
        uploadUsers,
        createUser,
        searchUsers,
        pageSize,
        getUserById,
        updateUser,
        hasRoleAccess
    }
}


export default useUsers
