/* eslint-disable react-hooks/exhaustive-deps */
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { useMemo } from 'react';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { Divider } from '@mui/material';
import { getPricetypeId } from './utils';

type Props = {
    shoes: ShoeData[];
    onDeleteRow: (id: string) => void;
    priceType: string;
};

type ShoeData = {
    _id: string;
    color: string;
    brand: string;
    totalPrice?: string;
    sizes: {
        id: string;
        name: string;
        quantity: string;
        prices: {
            _id: string;
            lista: string;
            price: string;
            porIva: string;
        }[];
    }[];
};

const OrderShoesTable = ({ shoes, onDeleteRow, priceType }: Props) => {

    const calculateTotal = useMemo(
        () => (prices: ShoeData['sizes'][0]['prices'], amount: string, priceType: string) => {
            let total = 0;
            const type = getPricetypeId(priceType)
            prices.forEach((price) => {
                if (price.lista === type) {
                    const priceValue = parseFloat(price.price);
                    const quantity = parseFloat(amount);
                    total += quantity * priceValue;
                }
            });
            return total;
        },
        []
    );

    const columns: GridColDef[] = [
        { field: 'brand', headerName: 'Marca', flex: 1 },
        {
            field: 'sizes',
            headerName: 'Talla (Cantidad)',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            renderCell: (params: any) => (
                <div>
                    {`${params.row.size} (${params.row.amountPeers})`}
                </div>
            ),
        },
        {
            field: 'color',
            headerName: 'Color',
            flex: 1, headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'total',
            headerName: 'Total',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            valueGetter: (params: any) => {
                const total = params.row.totalPrice ?? calculateTotal(params.row.prices, params.row.amountPeers, priceType);
                return params.row.totalPrice ? `$${params.row.totalPrice}` : new Intl.NumberFormat('es-CO', {
                    style: 'currency',
                    currency: 'COP',
                    maximumFractionDigits: 0,
                }).format(total);
            },
        },
        {
            field: 'delete',
            headerName: 'Eliminar',
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params: { row: { _id: string } }) => (
                <IconButton
                    style={{ cursor: 'pointer', color: 'red' }}
                    onClick={() => onDeleteRow(params.row._id)}
                >
                    <DeleteIcon />
                </IconButton>
            ),
        },
    ];
    // Calcular el total de todas las filas
    const totalOfAllRows = useMemo(() => {
        return shoes.reduce((total, row: any) => {
            const rowTotal = row.totalPrice ? parseFloat(row.totalPrice.replace(/\./g, '')) : calculateTotal(row.prices, row.amountPeers, priceType);
            return total + rowTotal;
        }, 0);
    }, [shoes, priceType]);

    return (
        <>
            {shoes.length > 0 && (
                <>
                    <Divider />
                    <DataGrid
                        key="shoes-data-grid"
                        className="conexsur-data-grid"
                        getRowId={(row) => row._id}
                        rows={shoes}
                        columns={columns}
                        hideFooter
                        sx={{ mt: 2 }}
                        loading={shoes.length === 0}
                    />
                    <div style={{ marginTop: '10px', textAlign: 'right' }}>
                        <strong>Total orden: </strong>
                        {new Intl.NumberFormat('es-CO', {
                            style: 'currency',
                            currency: 'COP',
                            maximumFractionDigits: 0,
                        }).format(totalOfAllRows)}
                    </div>
                </>
            )}
        </>
    );
};

export default OrderShoesTable;
