import { Step, StepButton, Stepper, Dialog, DialogActions, DialogContent, DialogContentText, Button, useMediaQuery, Chip } from '@mui/material';
import { Box, color } from '@mui/system';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import React, { useState } from 'react';
import useOrders from '../../hooks/useOrders';
import { useParams } from 'react-router-dom';

type Props = {
    activeStep: string;
};

const steps = ["Borrador", "Completada", "Aprobado", "En curso", "Facturacion", "Enviado"];

const OrderStatusSteps = ({ activeStep }: Props) => {
    const [open, setOpen] = useState(false);
    const [currentStep, setCurrentStep] = useState('');
    const [activeStepIndex, setActiveStepIndex] = useState(steps.indexOf(activeStep));
    const { updateOrderState } = useOrders();
    const { id } = useParams()

    const isMobile = useMediaQuery('(max-width:600px)');

    const handleClickOpen = (label: string) => {
        setCurrentStep(label);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleStepChange = async () => {
        if (!id) return;
        updateOrderState(id, currentStep)
            .then(() => {
                setActiveStepIndex(steps.indexOf(currentStep));
                setOpen(false);
            })
            .catch(() => {
                setOpen(false);
            })
    }

    return (
        <>
            {isMobile ? <Box display="flex" justifyContent="space-between" sx={{ my: 2 }}>
                <Button
                    variant="outlined"
                    onClick={() => handleClickOpen(steps[activeStepIndex - 1])}
                    disabled={activeStepIndex === 0}
                    startIcon={<ArrowBackIosIcon />}
                >
                    Anterior
                </Button>
                <Chip color="primary" label={steps[activeStepIndex]} />
                <Button
                    variant="outlined"
                    onClick={() => handleClickOpen(steps[activeStepIndex + 1])}
                    disabled={activeStepIndex === steps.length - 1}
                    endIcon={<ArrowForwardIosIcon />}
                >
                    Siguiente
                </Button>

            </Box>
                :
                <Stepper activeStep={activeStepIndex} nonLinear sx={{ my: 2 }}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepButton
                                onClick={() => handleClickOpen(label)}
                                disabled={steps.indexOf(label) > activeStepIndex + 1 || steps.indexOf(label) < activeStepIndex - 1}
                            >
                                {label}
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>
            }
            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    <DialogContentText>
                        Esta seguro que quiere pasar la orden a "{currentStep}"?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button onClick={handleStepChange}>Confirmar</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default OrderStatusSteps;
