import * as yup from 'yup';


const ordersValidationSchema = yup.object().shape({
    date: yup.string().required('La fecha es requerida'),
    city: yup.string().required('La ciudad es requerida'),
    department: yup.string().required('El departamento es requerido'),
    priceType: yup.string().required('El tipo de precio es requerido'),
    invoiceType: yup.string().required('El tipo de documento es requerido'),
    shoes: yup.array().required(),
    addres: yup.string().required('La direccion es requerida'),
    documentType: yup.string().required('El tipo de documento es requerido'),
    clientMail: yup.string().email('Debe ser un email válido').required('El correo del cliente es requerido'),
    client: yup.string().required('El cliente es requerido'),
    paymentMethod: yup.string().required('El tipo de pago es requerido'),
    term: yup.number().required('El termino es requerido'),
    financialDiscount: yup.number().required()
});

export { ordersValidationSchema as default }