import React from 'react';
import {Typography} from '@mui/material';

type Props = {
    text: string
}

const ViewHeader = ({text}: Props) => {
    return (
        <Typography sx={{typography: {sm: 'h2', xs: 'h4'}}} mb={1}>
            {text}
        </Typography>
    )
}

export default ViewHeader