import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import routes from './routes/routes';
import Layout from './components/layout/Layout';
import { ThemeProvider } from '@mui/material';
import { appTheme } from './theme/theme';
import useConfig from './hooks/useConfig';
import AppLoader from './components/layout/AppLoader';
import PrivateRoute from './routes/PrivateRoute';

function App() {
    const { loadConfigs } = useConfig();
    const token = localStorage.getItem("access");
    const [loadingConfigs, setLoadingConfigs] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (token) {
                    await loadConfigs();
                }
                setLoadingConfigs(false);
            } catch (error) {
                setLoadingConfigs(false);
            }
        };

        fetchData();
    }, [token]);

    if (loadingConfigs) {
        return <AppLoader text="Cargando configuraciones..." />;
    }

    return (
        <ThemeProvider theme={appTheme}>
            <BrowserRouter>
                <Routes>
                    <Route path={routes.login.route} element={routes.login.component} />
                    <Route element={<PrivateRouteWithLayout />}>
                        <Route path={routes.home.route} element={<PrivateRoute route={routes.home} component={routes.home.component} />} />
                        <Route path={routes.users.route} element={<PrivateRoute route={routes.users} component={routes.users.component} />} />
                        <Route path={routes.newUser.route} element={<PrivateRoute route={routes.newUser} component={routes.newUser.component} />} />
                        <Route path={routes.editUser.route} element={<PrivateRoute route={routes.editUser} component={routes.editUser.component} />} />
                        <Route path={routes.inventory.route} element={<PrivateRoute route={routes.inventory} component={routes.inventory.component} />} />
                        <Route path={routes.orders.route} element={<PrivateRoute route={routes.orders} component={routes.orders.component} />} />
                        <Route path={routes.newOrder.route} element={<PrivateRoute route={routes.newOrder} component={routes.newOrder.component} />} />
                        <Route path={routes.editOrder.route} element={<PrivateRoute route={routes.editOrder} component={routes.editOrder.component} />} />
                        <Route path={routes.shoes.route} element={<PrivateRoute route={routes.shoes} component={routes.shoes.component} />} />
                        <Route path={routes.clients.route} element={<PrivateRoute route={routes.clients} component={routes.clients.component} />} />
                        <Route path={routes.viewClient.route} element={<PrivateRoute route={routes.viewClient} component={routes.viewClient.component} />} />
                    </Route>
                    <Route path="*" element={<Navigate to={routes.home.route} />} />
                </Routes>
            </BrowserRouter>
        </ThemeProvider>
    );
}

// Componente funcional de nivel superior que contiene la redirección condicional
function PrivateRouteWithLayout() {
    const navigate = useNavigate();
    const token = localStorage.getItem("access");
    const location = useLocation();

    // Si el token es nulo, redirige al login
    useEffect(() => {
        if (!token && location.pathname !== routes.login.route) {
            navigate(routes.login.route);
        }
    }, [token, navigate]);

    return <Layout />;
}

export default App;
