import { createSlice } from "@reduxjs/toolkit";
import { emptyRow } from "../../constants/orders/orders";

export interface IOrderStore {
    orderConfig: IOrderConfig,
    total: number,
    totalPairs: number,
    totalDiscount: number,
    shoes: IShoe[]; // Array de objetos Shoe
}

interface IOrderConfig {
    client: string; // ID del cliente (mongoose.Schema.Types.ObjectId)
    salesPerson: string; // Nombre del vendedor (String)
    date: string; // Fecha de la venta (String)
    origin: string; // Origen de la venta (String: "01", "02", "03", "04", "05", "06", "07")
    addres: string; // Dirección (String)
    city: string; // ID de la ciudad (mongoose.Schema.Types.ObjectId)
    phone: string; // Número de teléfono (String)
    paymentMethod: string; // Método de pago (String: "CONTADO" o "CREDITO")
    destination: string; // Destino de la venta (String: "01", "02", "03", "04", "05", "06", "07")
    department: string; // ID del departamento (mongoose.Schema.Types.ObjectId)
    invoiceType: string; // Tipo de factura (String: "PEDIDO", "DEVOLUCION", "GARANTIAS", "TRASLADO", "ENTRADAS", "SALIDAS")
    operationNumber: number; // Número de operación (Number)
    financialDiscount: number; // Descuento financiero (Number)
    term: number; // Término (Number)
    documentType: string; // Tipo de documento (String: "PEDIDO", "DEVOLUCION", "GARANTIAS", "TRASLADO", "ENTRADAS", "SALIDAS")
    priceType: string; // Tipo de precio (String: "PUBLICO", "COMERCIO", "ESPAECIAL")
    clientMail: string; // Correo electrónico del cliente (String)
}

interface IShoe {
    id: string; // ID del zapato (mongoose.Schema.Types.ObjectId)
    shoeId: string; // ID del zapato (mongoose.Schema.Types.ObjectId)
    shoePrriceId: string; // ID del precio del zapato (mongoose.Schema.Types.ObjectId)
    amountPairs: number; // Cantidad de pares de zapatos (Number)
    discount: number; // Descuento aplicado al zapato (Number)
    shoePrrice: string; // Precio unitario del zapato (String)
    shoePriceDiscount: string; // Precio unitario del zapato con descuento (String)
    shoePriceOptions: Array<any>; // Opciones del precio del zapato (Array)
    totalPrice: number; // Precio total del zapato con descuento (String)
    totalPair: number; // Cantidad de pares de zapatos (Number)
    amountPeers: number; // Cantidad de pares de zapatos (Number)
}

const initialState: IOrderStore = {
    orderConfig: {
        client: "",
        salesPerson: "",
        date: "",
        origin: "",
        addres: "",
        city: "",
        phone: "",
        paymentMethod: "",
        destination: "",
        department: "",
        invoiceType: "",
        operationNumber: 0,
        financialDiscount: 0,
        term: 0,
        documentType: "",
        priceType: "01",
        clientMail: "",
    },
    total: 0,
    totalPairs: 0,
    totalDiscount: 0,
    shoes: [emptyRow()]
};

export const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        setOrderConfig: (state, action) => {
            // if priceType changes recalculate total price for each shoe using the shoePriceOptions Array
            if (action.payload.priceType !== state.orderConfig.priceType) {
                let newShoes = [...state.shoes]
                state.shoes = newShoes.map(shoe => {
                    let newShoe = {...shoe}
                    let shoePrice = shoe.shoePriceOptions.find((price: any) => price.type === action.payload.priceType) ?? 0;
                    newShoe.shoePrrice = shoePrice.price;
                    newShoe.shoePriceDiscount = shoePrice.priceDiscount;
                    newShoe.shoePrriceId = shoePrice._id;
                    return newShoe;
                })
            }
            // if financialDiscount changes recalculate discount for each shoe
            // if (action.payload.financialDiscount !== state.orderConfig.financialDiscount) {
            //     let newShoes = [...state.shoes]
            //     state.shoes = newShoes.map(shoe => {
            //         let newShoe = {...shoe}
            //         let shoePrice = shoe.shoePriceOptions.find((price: any) => price.type === action.payload.priceType) ?? 0;
            //         newShoe.discount = action.payload.financialDiscount ? Math.floor(shoe.totalPrice * (action.payload.financialDiscount/ 100)) : 0;
            //         newShoe.shoePriceDiscount = `${Math.floor(shoe.totalPrice * (action.payload.financialDiscount/ 100))}`;
            //         newShoe.totalPrice = (Number(newShoe.shoePrrice) - Number(newShoe.discount)) * newShoe.totalPair;
            //         const totalPrice = shoePrice.price * shoe.totalPair;
            //         newShoe.totalPrice =  action.payload.financialDiscount ? totalPrice - newShoe.discount :  totalPrice;
            //         return newShoe;
            //     })
            // }
            let total = 0;
            let totalPairs = 0;
            let totalDiscount = 0;
            state.shoes.forEach(shoe => {
                total += Number(shoe.totalPrice ?? 0)
                totalPairs += Number(shoe.totalPair ?? 0)
                totalDiscount += Number(shoe.shoePriceDiscount ?? 0)
            })
            state.total = total;
            state.totalPairs = totalPairs;
            state.totalDiscount = totalDiscount;
            state.orderConfig = action.payload
        },
        updateShoes: (state, action) => {
            const newShoes = action.payload.map((shoe: any) => {
                let newShoe = {...shoe}
                let shoePrice = shoe.shoePriceOptions.find((price: any) => price.type === state.orderConfig.priceType) ?? 0;
                newShoe.shoeId = shoePrice.shoe_id;
                newShoe.shoePrrice = shoePrice.price;
                newShoe.shoePriceDiscount = `${Math.floor(shoe.totalPrice * (newShoe.discount/ 100))}`;
                newShoe.shoePrriceId = shoePrice._id;
                newShoe.totalPrice = (Number(newShoe.shoePrrice) - (newShoe.shoePrrice * (newShoe.discount)/100)) * newShoe.totalPair;
                return newShoe;
            })
            state.shoes = newShoes;
            // sum all shoe prices and set total
            let total = 0;
            let totalPairs = 0;
            let totalDiscount = 0;
            newShoes.forEach((shoe:any) => {
                total += Number(shoe.totalPrice ?? 0)
                totalPairs += Number(shoe.totalPair ?? 0)
                totalDiscount += Number(shoe.shoePriceDiscount ?? 0)
            })
            state.total = total;
            state.totalPairs = totalPairs;
            state.totalDiscount = totalDiscount;
        },
        resetOrder: (state) =>  state = {...initialState}
    }
})
export const {setOrderConfig, updateShoes, resetOrder} = orderSlice.actions;

export default orderSlice.reducer;