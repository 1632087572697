import { useState } from "react";
import { axiosNode } from "../api/axios";
import { apiUrls } from "../api/apiUrls";
import useNotify from "./useNotify";
import { ConvertedData } from "../pages/orders/utils";
import { useNavigate } from "react-router-dom";
import routes from "../routes/routes";


interface Shoe {
    shoeId: string;
    shoePrriceId: string;
    amountPeers: number;
    discount: number;
    shoePrrice: string;
    shoePriceDiscount: string;
    totalPrice: string;
}

interface Order {
    client: string;
    salesPerson: string;
    date: string;
    origin: string;
    addres: string;
    city: string;
    phone: string;
    paymentMethod: string;
    destination: string;
    department: string;
    invoiceType: string;
    operationNumber: number;
    financialDiscount: number;
    term: number;
    documentType: string;
    priceType: string;
    clientMail: string;
    shoes: Shoe[];
}

const useOrders = () => {
    const [orders, setOrders] = useState<Order[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const { showMessage } = useNotify()
    const navigate = useNavigate();

    const getOrders = async () => {
        try {
            const response = await axiosNode.post(apiUrls.orders.PostGetOrders);
            setOrders(response.data.results);
            setLoading(false);
            return response.data.results;
        } catch (error: any) {
            return error
        }
    };

    const getOrderById = async (id: string) => {
        try {
            const response = await axiosNode.get(`${apiUrls.orders.GetOrderById}/${id}`);
            return response.data;
        } catch (error: any) {
            return error
        }
    };

    // const createOrder = async (order: ConvertedData) => {
    //     try {
    //         const res = await axiosNode.post(apiUrls.orders.PostCreateOrder, order);
    //         if (res.data.errors) {
    //             throw res.data.errors;
    //         }
    //     } catch (errors: any) {
    //         errors.forEach((error: any) => {
    //             showMessage(error.msg, 'error')
    //         });
    //         return errors
    //     }
    // };

    const createOrder = (order: any) => {
        return new Promise<void>((resolve, reject) => {
            axiosNode.post(apiUrls.orders.PostCreateOrder, order)
                .then(res => {
                    if (res.data.errors) {
                        throw res.data.errors
                    } else {
                        showMessage('Orden creada correctamente', 'success');
                        navigate(routes.orders.route)
                        resolve();
                    }
                })
                .catch(errors => {
                    errors.forEach((error: any) => {
                        showMessage(error.msg, 'error');
                    });
                    reject(errors);
                });
        });
    };
    const editOrder = async (id: string, order: ConvertedData) => {
        try {
            const res = await axiosNode.put(`${apiUrls.orders.PostCreateOrder}/${id}`, order);
            if (res.data.errors) {
                throw res.data.errors;
            } else {
                showMessage('Orden editada correctamente', 'success');
                navigate(routes.orders.route);
            }
        } catch (errors: any) {
            errors.forEach((error: any) => {
                showMessage(error.msg, 'error');
            });
        }
    };

    const updateOrderState = async (id: string, state: string) => {
        try {
            const res = await axiosNode.put(`${apiUrls.orders.PutOrdenDeCompraState}/${id}`, { stateOrdern: state });

            if (res.data.error) {
                throw res.data.error;
            } else {
                showMessage('Estado actualizado correctamente', 'success');
            }
        } catch (error: any) {
            showMessage(error.response.data.error, 'error');
            return new Error(error);

        }
    }
    return { getOrders, orders, loading, createOrder, getOrderById, editOrder, updateOrderState };
};

export default useOrders;
