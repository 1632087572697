export const BASE_URL: string = (process.env.REACT_APP_API_BASE_URL as string);
export const NODE_API_BASE_URL: string = (process.env.REACT_APP_NODE_API_BASE_URL as string)

export interface IApiInterface {
    security: {
        PostTokenCreate: string,
        GetCurrentUser: string,
        PostCreateUser: string,
        GetUsers: string,
        PutUsers: string,
        GetRoles: string,
        GetSearchUsers: string,
        GetUserById: string,
        PutUpdateUser: string,
    },
    products: {
        PostUploadInventory: string,
        PostUploadPrice: string,
        PostReportsAmount: string,
        GetProductsColors: string,
        GetProductsSizes: string,
        GetProductsBrands: string,
        PostProductsColors: string,
        PostProductsSizes: string,
        PostProductsBrands: string,
        GetProductShoe: string
    },
    geo: {
        GetCities: string,
        GetRegions: string,
    },
    orders: {
        PostGetOrders: string,
        PostCreateOrder: string,
        GetOrderById: string,
        PutOrdenDeCompraState: string
    },
    clients: {
        GetClients: string,
        PostUploadClients: string,
        GetClientById: string,
    }

}

export const apiUrls: IApiInterface = {
    security: {
        PostTokenCreate: 'user/login',
        GetCurrentUser: 'security/auth/users',
        PostCreateUser: 'user',
        GetUsers: 'security/auth/sellers',
        GetUserById: 'user',
        PutUsers: 'security/auth/file',
        GetRoles: 'security/auth/groups',
        GetSearchUsers: 'user/1',
        PutUpdateUser: 'user'
    },
    products: {
        PostUploadInventory: 'productshoenew/shoeDocument',
        PostUploadPrice: 'productshoenew/shoeDocument/price',
        PostReportsAmount: 'products/reports-amount/',
        GetProductsColors: 'productcolor/1/100',
        GetProductsSizes: 'productsize/1/100',
        GetProductsBrands: 'productbrand/1/100',
        PostProductsColors: 'productcolor',
        PostProductsSizes: 'productsize',
        PostProductsBrands: 'productbrand',
        GetProductShoe: 'productshoe/1/10'
    },
    geo: {
        GetCities: '/city/1/100',
        GetRegions: 'region/1/100'
    },
    orders: {
        PostGetOrders: 'ordencompra/1/100',
        PostCreateOrder: 'ordencompra',
        GetOrderById: 'ordencompra',
        PutOrdenDeCompraState: 'ordencompra/state'
    },
    clients: {
        GetClients: 'clients',
        PostUploadClients: 'clients/clientdocument',
        GetClientById: 'clients'
    }
}