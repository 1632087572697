import { GridColDef, GridValueSetterParams } from '@mui/x-data-grid';
import OrdersAutocomplete from '../../components/orders/OrdersAutocomplete';

const sizes = [...Array(26)].map((_, i) => ({
    headerName: `${i + 19}`,
    width: 20,
    type: "number",
    field: `${i + 19}`,
    editable: true,
    valueGetter: (params: any) => (
        params.row.sizesQtys[`${i + 19}`] 
    ),
    valueSetter: (params: GridValueSetterParams) => {
        if(params.value){
            const newSizezQtys = {...params.row.sizesQtys, [`${i + 19}`]: parseInt(params.value) }
            const totalSizez = Object.values(newSizezQtys).reduce((acc: any, value) => acc + value, 0);
            return { ...params.row, sizesQtys: newSizezQtys, totalPair: totalSizez }
        }else{
            const newSizezQtys = {...params.row.sizesQtys, [`${i + 19}`]: null }
            const totalSizez = Object.values(newSizezQtys).reduce((acc: any, value) => acc + value, 0);
            return { ...params.row, sizesQtys: newSizezQtys, totalPair: totalSizez }
        }
    }
}))

const getInvoiceTypeString = (priceType: string) => {
    switch (priceType) {
        case "01":
            return "PEDIDO"
        case "02":
            return "DEVOLUCION"
        case "03":
            return "GARANTIAS"
        case "04":
            return "TRASLADO"
        case "05":
            return "ENTRADAS"
        case "06":
            return "SALIDAS"
        default:
            return "PEDIDO" 
    }
}

const getPriceTypeString = (priceType: string) => {
    //"PUBLICO", "COMERCIO", "ESPAECIAL"
    switch (priceType) {
        case "01":
            return "PUBLICO"
        case "02":
            return "COMERCIO"
        case "03":
            return "ESPECIAL"
        default:
            return "PUBLICO" 
    }
}

// format total to COP currency no decimals
const formatToMoney = (total: number) => {
    return new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP', maximumFractionDigits: 0 }).format(total)
}

const ordersColumns: GridColDef[] = [
    {
        headerName: "Código",
        width: 150,
        type: "singleSelect",
        field: "description",
        editable: true,
        renderEditCell: (params) => {
            return <OrdersAutocomplete rowParams={params} />
        },
    },
    {
        headerName: "Color",
        width: 150,
        type: "singleSelect",
        field: "colorId",
        editable: true,
        valueOptions: (params) => {
            return params.row.colorIdOptions
        },
        getOptionValue: (value: any) => value._id,
        getOptionLabel: (value: any) => value.name
    },
    {
        headerName: "Rango",
        width: 150,
        type: "singleSelect",
        editable: true,
        valueOptions: (params) => {
            const colorRange = params.row.colorIdOptions.find((el:IShoe)=> el._id === params.row.colorId)
            return  colorRange? colorRange.size_id : []
        },
        getOptionValue: (value: any) => value._id,
        getOptionLabel: (value:any) => value.name,
        valueSetter: (params) =>  {
            // get redux value order.orderConfig.priceType
            // set price depending on range and redux value order.orderConfig.priceType
            const colorRange = params.row.colorIdOptions.find((el:IShoe)=> el._id === params.row.colorId)
            const sizeRange = colorRange?.size_id.find((el:any)=> el._id === params.value)
            const price = sizeRange?.price
            const discount = params.row.discount
            const shoePriceOptions = price ? price : 0
            const shoePriceDiscount = price ? price - (price * discount / 100) : 0
            const totalPrice = shoePriceDiscount * params.row.amountPairs
            return { ...params.row, range: params.value, shoePriceOptions, shoePriceDiscount, totalPrice }
        },
        field: "range",
        
    },
    {
        headerName: "Par",
        width: 120,
        type: "text",
        field: "pair"
    },
    ...sizes,
    {
        headerName: "Total Par",
        width: 120,
        type: "text",
        field: "totalPair",
    },
    {
        headerName: "Precio Unitario",
        width: 100,
        type: "text",
        field: "shoePrice",
        valueFormatter(params) {
            return formatToMoney(params.value)
        }
    },
    {
        headerName: "Descuento",
        width: 100,
        type: "number",
        field: "discount",
        editable: true,
    },
    {
        headerName: "Precio Total",
        width: 100,
        type: "text",
        field: "totalPrice",
        valueFormatter(params) {
            return formatToMoney(params.value)
        },
    }

];

const emptyRow = () => {

    return {
        id: crypto.randomUUID(),
        shoeId: "",
        shoePrriceId: "",
        amountPairs: 0,
        discount: 0,
        shoePriceDiscount: "",
        shoePriceOptions: [],
        shoePrrice: "",
        totalPrice: 0,
        color: "",
        description: "",
        sizeId: [],
        colorIdOptions: [],
        sizesQtys: {},
        totalPair: 0,
        amountPeers: 0,
        // id: string; // ID del zapato (mongoose.Schema.Types.ObjectId)
        // shoeId: string; // ID del zapato (mongoose.Schema.Types.ObjectId)
        // shoePriceId: string; // ID del precio del zapato (mongoose.Schema.Types.ObjectId)
        // amountPairs: number; // Cantidad de pares de zapatos (Number)
        // discount: number; // Descuento aplicado al zapato (Number)
        // shoePrice: string; // Precio unitario del zapato (String)
        // shoePriceDiscount: string; // Precio unitario del zapato con descuento (String)
        // totalPrice: string;
    }
}
interface IPrice {
    state: boolean;
    dateCreated: string;
    dateUpdate: string;
    dateDeleted: string;
    _id: string;
    type: string;
    price: number;
    shoe_id: string;
    idMysql: number;
    __v: number;
  }
  
  interface IShoe {
    totalPairs: number;
    amountPeers: number;
    idMysql: number;
    price: IPrice[];
    _id: string;
    name: string;
    idshoe: string;
    shoePrrice: number;
    shoePrriceId: string;
  }



export { ordersColumns, emptyRow, getInvoiceTypeString, getPriceTypeString };
    export type { IShoe };

