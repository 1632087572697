import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import routes, { IRoute } from './routes';
import { useSelector } from 'react-redux';
import { IRootStore } from '../store/store';

interface IPrivateRoute {
    route: IRoute;
    component: ReactNode;
}

const PrivateRoute = ({ route, component }: IPrivateRoute) => {
    const token = localStorage.getItem('access');
    const currentUser = useSelector((state: IRootStore) => state.auth.currentUser);

    const hasAccess = (): boolean => {
        const roleIsPresent = route?.access.filter((role: number) => role === currentUser?.group_id || role === 0).length;
        return roleIsPresent > 0;
    };

    if (!token) {
        return <Navigate to={routes.login.route} />;
    }

    if (!hasAccess()) {
        return <Navigate to={routes.home.route} />;
    }

    return React.isValidElement(component) ? component : <>{component}</>;
};

export default PrivateRoute;
