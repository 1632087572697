import {configureStore} from '@reduxjs/toolkit';
import configReducer, {IConfigStore} from './config/configSlice';
import authReducer, {IAuthStore} from './auth/authSlice';
import productReducer, { IProductsStore } from './products/productsSlice'; 
import orderReducer, { IOrderStore} from './order/orderSlice';

export interface IRootStore {
    config: IConfigStore,
    auth: IAuthStore,
    products: IProductsStore,
    order: IOrderStore
}

export const store = configureStore({
    reducer: {
        config: configReducer,
        auth: authReducer,
        products: productReducer,
        order: orderReducer
    }
})