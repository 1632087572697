import React, { useState } from 'react'
import { AppBar, Box, Menu, MenuItem, Toolbar, Typography } from '@mui/material'
import IconButton from '@mui/material/IconButton';
import { AccountCircle, Menu as MenuIcon } from '@mui/icons-material';
import useSecurity from '../../hooks/useSecurity';

import Drawer from './Drawer'
import { useSelector } from 'react-redux';
import { IRootStore } from '../../store/store';

import ConexsurLogo from '../../assets/logo192.png'
import { ERoles } from '../../constants/users/users';

const Appbar = () => {

    const [drawerIsOpen, setDrawerIsOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const currentUser = useSelector((state: IRootStore) => state.auth.currentUser)

    const { handleLogout } = useSecurity()

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const UserRole = Object.keys(ERoles).find((key: string) => ERoles[key as keyof typeof ERoles] === currentUser?.group_id)


    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed" color="primary">
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={() => setDrawerIsOpen((state) => !state)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <img src={ConexsurLogo} alt='conexsur-logo' style={{ height: '2em', width: 'auto' }} />
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Conexsur
                    </Typography>

                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        color="inherit"
                    >
                        <Typography variant="h6" component="div" sx={{ mr: 2, display: { xs: 'none', md: 'block' } }}>
                            {currentUser?.username}
                        </Typography>
                        <AccountCircle />
                    </IconButton>

                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem disabled >{UserRole}</MenuItem>
                        <MenuItem onClick={() => handleLogout()}>Cerrar sesión</MenuItem>
                    </Menu>

                </Toolbar>
            </AppBar>
            <Drawer
                isOpen={drawerIsOpen}
                handleClose={() => setDrawerIsOpen(false)}
            />
        </Box>
    )
}

export default Appbar
