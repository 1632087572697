import React, { useState } from 'react'
import { UploadFile } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import useNotify from '../../hooks/useNotify'
import useClients from '../../hooks/useClients'

type Props = {
    apiUrl: string,
    handleCancel: () => void
}

const FileUpload = (props: Props) => {

    const { handleCancel } = props;

    const [selectedFile, setSelectedFile] = useState<any>(null);
    const [isUploading, setIsUploading] = useState<boolean>(false);

    const { uploadClients } = useClients()
    const { showMessage } = useNotify()

    const handleFileSelect = (event: any) => {
        setSelectedFile(event.target.files[0])
    }

    const handleUpload = () => {
        setIsUploading(true)
        uploadClients(selectedFile)
            .then((res) => {
                if (res.status === 200 && !res.data.errors) {
                    setIsUploading(false)
                    showMessage(res.data, 'success')
                } else {
                    throw res.data.errors
                }
                setSelectedFile(null)
            })
            .catch((errors: any) => {
                errors.forEach((error: any) => {

                    showMessage(error.msg, 'error')
                });
                setIsUploading(false)
                setSelectedFile(null)
            })
    }

    return (
        <>
            <Box display='flex' flexDirection='column' alignItems='center' mt={2}>
                <Button
                    startIcon={<UploadFile />}
                    component='label'
                    variant='outlined'
                    color='secondary'
                    disabled={isUploading}>
                    Elegir archivo
                    <input
                        type='file'
                        onChange={handleFileSelect}
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        hidden />
                </Button>
                {
                    selectedFile &&
                    <Typography variant='caption'>{selectedFile.name}</Typography>
                }
            </Box>
            <Box display='flex' justifyContent='flex-end' mt={2}>
                <Button
                    variant='outlined'
                    color='error'
                    sx={{ mr: 1 }}
                    disabled={isUploading}
                    onClick={() => {
                        setSelectedFile(null)
                        handleCancel()
                    }}
                >
                    Cancelar
                </Button>
                <LoadingButton
                    variant='contained'
                    color='success'
                    onClick={() => handleUpload()}
                    disabled={!selectedFile || isUploading}
                    loading={isUploading}
                >
                    Subir
                </LoadingButton>
            </Box>
        </>
    )
}

export default FileUpload