import React, { useState } from 'react'
import { UploadFile } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import useInventory from '../../hooks/useInventory'
import useNotify from '../../hooks/useNotify'
import UploadLogModal from './UploadLogModal'

type Props = {
    apiUrl: string,
    file: any,
    fileName: string,
    name: string,
}

const FileUpload = (props: Props) => {

    const { apiUrl, file, fileName, name } = props;

    const [selectedFile, setSelectedFile] = useState<any>(null);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [uploadData, setUploadData] = useState<any>({ status: null, data: [] });
    const [isModalOpen, setisModalOpen] = useState<boolean>(false);

    const { uploadInventory } = useInventory()
    const { showMessage } = useNotify()

    const handleFileSelect = (event: any) => {
        setUploadData({ status: null, data: [] })
        setSelectedFile(event.target.files[0])
    }

    const handleUpload = () => {
        setIsUploading(true)
        uploadInventory(selectedFile, apiUrl)
            .then((res) => {
                setIsUploading(false)
                setSelectedFile(null)
                if (res?.response?.status === 400) {
                    setUploadData({ status: 'exitoso', data: res?.response.data.data })
                    showMessage('Creado con errores, revise el registro de carga', 'warning')
                } else {
                    setUploadData({ status: 'exitoso', data: res?.data.data })
                    showMessage(res.data.message, 'success')
                }

            })
            .catch((err) => {
                setUploadData({ status: 'fallido', data: err.data.data })
                setIsUploading(false)
                setSelectedFile(null)
            })
    }

    return (
        <>
            <UploadLogModal
                isOpen={isModalOpen}
                name={name}
                handleClose={() => setisModalOpen(false)}
                data={uploadData}
            />
            <Box display='flex' flexDirection='column' alignItems='center' mt={2}>
                <Button
                    startIcon={<UploadFile />}
                    component='label'
                    variant='outlined'
                    color='secondary'
                    disabled={isUploading}>
                    Elegir archivo
                    <input type='file' onChange={handleFileSelect} hidden />
                </Button>
                {
                    selectedFile &&
                    <Typography variant='caption'>{selectedFile.name}</Typography>
                }
            </Box>
            <Box display='flex' alignItems='center' justifyContent='flex-end' mt={2} sx={{ gap: 1 }}>
                {
                    uploadData.data.length > 0 &&
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={() => setisModalOpen(true)}
                    >
                        Ver registro de carga
                    </Button>
                }
                <Button
                    variant='outlined'
                    color='success'
                    href={file}
                    target="_blank"
                    rel="noopener noreferrer"
                    download={fileName}
                >
                    Descargar plantilla
                </Button>
                <Button
                    variant='outlined'
                    color='error'
                    disabled={isUploading}
                    onClick={() => setSelectedFile(null)}
                >
                    Cancelar
                </Button>
                <LoadingButton
                    variant='contained'
                    color='success'
                    onClick={() => handleUpload()}
                    disabled={!selectedFile || isUploading}
                    loading={isUploading}
                >
                    Subir
                </LoadingButton>
            </Box>
        </>
    )
}

export default FileUpload