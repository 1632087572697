// @ts-nocheck
import { Autocomplete, Button, Card, CardContent, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { axiosNode } from '../../api/axios';
import { Box, color } from '@mui/system';
import useDebounce from '../../hooks/utils/useDebounce';

type Props = {}


const getShoes = async (filters: object) => {
    const query = {
        "query": { description: filters.productBrand },
        "values": { productBrand: '', productColor: filters.productColor, productSize: filters.productSize }
    }
    return axiosNode.post('productshoe/1/100', query)
        .then(res =>
            res.data.zapatos
        )
}

const filtersInitState = {
    productBrand: '',
    productColor: '',
    productSize: ''
}
const Shoes = (props: Props) => {
    const [shoes, setShoes] = useState([]);
    const [colors, setColors] = useState([]);
    const [query, setQuery] = useState('');
    const [result, setResult] = useState({});
    const [filters, setFilters] = useState({
        productBrand: '',
        productColor: '',
        productSize: ''
    })
    const [isReseted, setIsReseted] = useState(false)
    const debouncedSearch = useDebounce(query);

    const handleShoes = async (brand, shoeFilters) => {
        const response = await getShoes(brand ? { ...shoeFilters, productBrand: brand } : shoeFilters)
        if (response.length > 0) {
            if (isResultReady()) {
                setResult(response[0])
            } else {
                setShoes(response)
            }
            // remove duplicate colors in response shoe.color_id.name
            if (shoeFilters.productColor === '') {
                const uniqueColors = [...new Set(response.map(shoe => shoe.color_id.name))];
                setColors(uniqueColors)
            }
        }

    }

    useEffect(() => {
        if (!isReseted) {
            handleShoes(query, filters)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearch, filters]
    )

    function isResultReady() {
        return Object.values(filters).every(value => typeof value === 'string' && value.trim() !== '');
    }

    function resetFilters() {
        setFilters(filtersInitState)
        setQuery('')
        setShoes([])
        setColors([])
        setIsReseted(true)
    }

    function priceType(price) {
        switch (price) {
            case "01":
                return "Público"
            case "02":
                return "Mayorista"
            case "03":
                return "Especial"
        }
    }

    function formatPrice(price: string) {
        return new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP', maximumFractionDigits: 0 }).format(parseInt(price))
    }

    function removeDuplicates(arr) {
        const uniqueBrands = new Set();

        // Use filter to create a new array with unique brand names
        const uniqueArray = arr.filter(item => {
            const brandName = item.brand_id.name;

            // Check if the brand name is already in the set
            // If not, add it to the set and return true (keep in the new array)
            // If yes, return false (filter it out from the new array)
            if (!uniqueBrands.has(brandName)) {
                uniqueBrands.add(brandName);
                return true;
            }
            return false;
        });

        return uniqueArray;
    }
    return (
        <>
            <Card>
                <CardContent>
                    <Typography variant='h5' sx={{ mb: 2 }}>
                        Zapatos
                    </Typography>
                    <Box>
                        <Autocomplete
                            id="brand"
                            filterOptions={(x) => x}
                            options={removeDuplicates(shoes).map(shoe => ({ id: shoe._id, label: shoe.brand_id.name, ...shoe }))}
                            value={filters.productBrand || null}
                            isOptionEqualToValue={(option, value) => option.brand_id.name === value}
                            onChange={(e, value, reason) => {
                                if (reason === 'clear') {
                                    setFilters(filtersInitState)
                                    setQuery('')
                                    return

                                }
                                setFilters({ ...filters, productBrand: value.brand_id.name })
                                setQuery('')
                            }}
                            // isOptionEqualToValue={ }
                            // getOptionLabel={(option) => option?.brand_id?.name}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    fullWidth
                                    label="Descripción"
                                    onChange={(e) => {
                                        setQuery(e.target.value)
                                        setIsReseted(false)
                                    }} />
                            }
                        />
                    </Box>
                    <Box mt={2}>
                        <FormControl fullWidth>
                            <InputLabel id="select-color">Color</InputLabel>
                            <Select
                                labelId="select-color"
                                id="demo-simple-select"
                                fullWidth
                                value={filters.productColor}
                                label="Color"
                                disabled={shoes.length === 0}
                                onChange={(e, value) => {
                                    setFilters({ ...filters, productColor: e.target.value, productSize: '' })
                                }}
                            // onChange={handleChange}
                            >
                                {colors.length > 0 && colors.map((color, i) =>
                                    <MenuItem key={color} value={color}>{color}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box mt={2}>
                        <FormControl fullWidth>
                            <InputLabel id="select-size">Talla</InputLabel>
                            <Select
                                labelId="select-size"
                                label="Color"
                                value={filters.productSize}
                                onChange={(e, value) => {
                                    setFilters({ ...filters, productSize: e.target.value })
                                }}
                                disabled={shoes.length === 0}
                            >
                                {shoes.length > 0 && shoes.map((size, i) =>
                                    <MenuItem key={size.size_id._id} value={size.size_id.name}>{size.size_id.name}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box mt={2}>
                        <Button variant='contained' onClick={() => resetFilters()}>Limpiar resultado</Button>
                    </Box>
                </CardContent>
            </Card>
            {isResultReady() && shoes.length > 0 &&
                <Card sx={{ mt: 2 }}>
                    <CardContent>
                        <Typography variant='h5' ml={1} mb={1} >
                            Resultado
                        </Typography>
                        <Divider />
                        <Grid container >
                            <Grid item xs={12} md={6} sx={{ p: 1 }}>
                                <Typography variant='h6' color={'secondary'}>
                                    <b>Descripción:</b>
                                </Typography>
                                <Typography variant='h6'>
                                    {result?.description}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6} sx={{ p: 1 }}>
                                <Typography variant='h6' color={'secondary'}>
                                    <b>Cantidad:</b>
                                </Typography>
                                <Typography variant='h6'>
                                    {result?.amount}
                                </Typography>
                            </Grid>
                            {/* <Grid item xs={12} md={6} sx={{ p: 1 }}>
                                <Typography variant='h6'>
                                    <b>:</b> {result?.amount}
                                </Typography>
                            </Grid> */}
                        </Grid>
                        <Typography variant='h6' ml={1} mt={2} >
                            Precios
                        </Typography>
                        <Divider />
                        <Grid container >
                            {
                                result.prices && result?.prices.map(price => (
                                    <Grid item xs={12} md={6} sx={{ p: 1 }}>
                                        <Typography variant='h6' color={'secondary'}>
                                            <b>{priceType(price.lista)}</b>
                                        </Typography>
                                        <Typography variant='h6'>
                                            {formatPrice(price.price)}
                                        </Typography>
                                    </Grid>

                                ))
                            }
                        </Grid>
                    </CardContent>
                </Card>
            }
        </>
    )
}

export default Shoes;