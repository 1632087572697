import {createTheme} from "@mui/material/styles";

export const appTheme = createTheme({
    palette: {
        primary: {
            main: '#243340'
        },
        secondary: {
            main: '#2A86BF'
        },
        success: {
            main: '#03A64A'
        },
        error: {
            main: '#F27B35'
        },
    },
});