import { createSlice } from "@reduxjs/toolkit";
import { IResponseTokenUser } from "../../api/models/response/IUserResponse";

export interface IAuthStore {
    token: string | null,
    currentUser: null | IResponseTokenUser
}

const initialState = {
    token: localStorage.getItem('access'),
    currentUser: parseJwt(localStorage.getItem('access') || '')
}
function parseJwt(token: string) {
    if (!token) return null
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setToken: (state, action) => {
            state.token = action.payload
        },
        setCurrentUser: (state, action) => {
            state.currentUser = parseJwt(action.payload)
        }
    }
})

export const { setToken, setCurrentUser } = authSlice.actions;

export default authSlice.reducer;