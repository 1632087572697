import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'
// @mui
import { Box, Button, IconButton } from '@mui/material'
import { DataGrid, esES, getGridStringOperators, GridColDef, GridFilterModel } from '@mui/x-data-grid'
import EditIcon from '@mui/icons-material/Edit';
// project settings
import routes from '../../routes/routes'
import useUsers from '../../hooks/useUsers'
import { IUser } from '../../api/models/response/IUserResponse'
import ViewHeader from '../../components/layout/ViewHeader';
import { roleById } from '../../constants/users/users';

type Props = {}

const Users = (props: Props) => {
    const { getUsers, users, isLoading, pageSize, hasRoleAccess } = useUsers();
    const [currentPage, setcurrentPage] = useState<number>(1);
    const navigate = useNavigate();


    useEffect(() => {
        getUsers({}, currentPage * 10)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    const onFilterChange = (filterModel: GridFilterModel) => {
        setcurrentPage(1)
        const filter = filterModel.items[0]
        getUsers({ [filter.field]: filter.value }, currentPage)
    }

    const columns: GridColDef[] = [
        {
            field: 'dni',
            headerName: 'DNI',
            headerAlign: 'center',
            align: 'left',
            width: 150,
            filterOperators: getGridStringOperators()
                .filter((operator) => operator.value === "equals"),
        },
        {
            field: 'username',
            headerName: 'Nombre de usuario',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            filterOperators: getGridStringOperators()
                .filter((operator) => operator.value === "contains"),
        },
        {
            field: 'phone',
            headerName: 'Teléfono',
            headerAlign: 'center',
            align: 'center',
            type: 'number',
            width: 110,
            disableColumnMenu: true,
            filterable: false,
        },
        // {
        //     field: 'department',
        //     headerAlign: 'center',
        //     headerName: 'Departamento',
        //     align: 'center',
        //     width: 150,
        //     disableColumnMenu: true,
        //     filterable: false,

        // },
        {
            field: 'city_name',
            headerAlign: 'center',
            headerName: 'Ciudad',
            align: 'center',
            flex: 1,
            disableColumnMenu: true,
            filterable: false,
            renderCell(params) {
                return params.row?.city_id?.name
            },
        },
        {
            field: 'address',
            headerAlign: 'center',
            headerName: 'Dirección',
            align: 'center',
            flex: 1,
            disableColumnMenu: true,
            filterable: false,
        },
        {
            field: "group_id",
            headerName: "Rol",
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            disableColumnMenu: true,
            filterable: false,
            renderCell(params) {
                return roleById(params.row.group_id)
            },
        },
        {
            headerName: 'Acciones',
            headerAlign: 'center',
            flex: 0.5,
            align: 'center',
            field: 'actions',
            renderCell: (params) => (
                <>
                    {
                        hasRoleAccess(routes.newUser.access) &&
                        <IconButton aria-label="" onClick={() =>
                            navigate(routes.editUser.route.replace(':id', params.row._id))
                        }>
                            <EditIcon sx={{ color: 'white.main' }} />
                        </IconButton>
                    }
                </>
            )
        }
        // {
        //     field: 'groups',
        //     headerName: 'Rol',
        //     type: 'singleSelect',
        //     valueOptions: roles.map(role => ({ label: role.name, value: role.pk })),
        //     renderCell: (params) => {
        //         return params.row.groups.reduce((acc: string, curr: any) => {
        //             return acc + curr.name;
        //         }, '');
        //     }
        // }
    ]
    return (
        <>
            <ViewHeader text='Usuarios' />
            <Box display={'flex'} justifyContent={'flex-end'} width='100' mb={4}>
                {
                    hasRoleAccess(routes.newUser.access) &&
                    <Link to={routes.newUser.route}>
                        <Button variant='contained' color='secondary'>
                            Crear Usuario
                        </Button>
                    </Link>
                }
            </Box>
            <Box sx={{
                height: 400,
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                {
                    <DataGrid
                        className='conexsur-data-grid'
                        rows={users.map((user: IUser, i: number) => ({ ...user, id: i }))}
                        columns={columns}
                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                        loading={isLoading}
                        filterMode='server'
                        onFilterModelChange={onFilterChange}
                        paginationMode="server"
                        rowCount={pageSize}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                        }
                    />
                }
            </Box>
        </>
    )
}

export default Users