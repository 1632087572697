import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Appbar from './Appbar'

const Layout = () => {
    const location = useLocation();
    return (
        <div>
            <Appbar />
            <div
                className={location.pathname === '/orders/ne' ? 'inner-layout-large' : 'inner-layout'}>
                <Outlet />
            </div>

        </div>
    )
}

export default Layout
